import { gql } from "@apollo/client";

const userQuery = gql`
	query user($id: String!) {
		user(id: $id) {
			name
			id
			email
			createdDate
			isConfirmed
			lastLogin
			profilePhoto
			title
			phoneNo
			location
			time
			zone
			# tourGuide
			# headOfTools {
			#	totalCount
			#}
			role {
				name
			}
			#tools(filterOptions: { name: "" }) {
			#	totalCount
			#}
			teams {
				id
			}
			defaultTeam {
				projects(id: $id) {
					totalCount
				}
				id
				name
				slug
				logos
				shareCode
				createdDate
				# tools {
				# 	totalCount
				# 	list {
				# 		id
				# 		sharable
				# 		createdDate
				# 		member
				# 		isConnected
				# 		shortDescription
				# 		statusLink
				# 		businessOwner {
				# 			id
				# 			name
				# 			email
				# 		}
				# 		tool {
				# 			id
				# 			icon
				# 			name
				# 			description
				# 			shortDescription
				# 			documentationLinks {
				# 				name
				# 				link
				# 			}
				# 			businessOwner
				# 			toolLink
				# 			statusLink
				# 			health
				# 		}
				# 	}
				# }
				users {
					totalCount
					list {
						id
						user {
							id
							name
							email
							isConfirmed
							profilePhoto
							createdDate
							title
						}
					}
				}
			}
		}
	}
`;

// const currentToolsFilteredQuery = gql`
// 	query user($id: String!, $name: String, $take: Int, $skip: Int) {
// 		user(id: $id) {
// 			defaultTeam {
// 				tools(filterOptions: { name: $name }, take: $take, skip: $skip) {
// 					totalCount
// 					list {
// 						id
// 						sharable
// 						createdDate
// 						businessOwner {
// 							id
// 							name
// 							email
// 						}
// 						tool {
// 							id
// 							icon
// 							name
// 							description
// 							shortDescription
// 							documentationLinks {
// 								name
// 								link
// 							}
// 							businessOwner
// 							toolLink
// 							statusLink
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// `;

// const currentToolsSharableFilteredQuery = gql`
// 	query user($id: String!, $name: String, $take: Int, $skip: Int) {
// 		user(id: $id) {
// 			defaultTeam {
// 				tools(filterOptions: { name: $name, sharable: true }, take: $take, skip: $skip) {
// 					totalCount
// 					list {
// 						id
// 						sharable
// 						createdDate
// 						businessOwner {
// 							id
// 							name
// 							email
// 						}
// 						tool {
// 							id
// 							icon
// 							name
// 							description
// 							shortDescription
// 							documentationLinks {
// 								name
// 								link
// 							}
// 							businessOwner
// 							toolLink
// 							statusLink
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// `;

const currentTeamTeammateQuery = gql`
	query user($id: String!, $skip: Int, $name: String) {
		user(id: $id) {
			defaultTeam {
				users(filterOptions: { name: $name }, skip: $skip) {
					totalCount
					list {
						id
						user {
							id
							name
							email
							isConfirmed
							lastLogin
							profilePhoto
							createdDate
							title
							role {
								name
							}
						}
					}
				}
			}
		}
	}
`;

const currentConfirmedTeamTeammateQuery = gql`
	query user($id: String!, $skip: Int, $name: String) {
		user(id: $id) {
			defaultTeam {
				users(filterOptions: { name: $name, isConfirmed: true }, skip: $skip) {
					totalCount
				}
			}
		}
	}
`;

const currentUserQuery = gql`
	query user($id: String!, $skip: Int, $name: String) {
		user(id: $id) {
			role {
				name
			}
			# tools(filterOptions: { name: $name }, skip: $skip) {
			# 	totalCount
			# 	list {
			# 		id
			# 		tool {
			# 			tool {
			# 				id
			# 				name
			# 				description
			# 				shortDescription
			# 				toolLink
			# 				statusLink
			# 				icon
			# 				documentationLinks {
			# 					name
			# 					link
			# 				}
			# 			}
			# 		}
			# 	}
			# }
		}
	}
`;

const currentTeamTeammateFilteredQuery = gql`
	query user($id: String!, $take: Int, $name: String, $skip: Int) {
		user(id: $id) {
			defaultTeam {
				users(filterOptions: { name: $name, isConfirmed: true }, take: $take, skip: $skip) {
					totalCount
					list {
						id
						user {
							id
							name
							email
							isConfirmed
							profilePhoto
							lastLogin
							title
							createdDate
						}
					}
				}
			}
		}
	}
`;

// const currentTeamToolsQuery = gql`
// 	query user($id: String!, $skip: Int, $name: String, $take: Int) {
// 		user(id: $id) {
// 			defaultTeam {
// 				tools(filterOptions: { name: $name }, skip: $skip, take: $take) {
// 					totalCount
// 					list {
// 						id
// 						sharable
// 						createdDate
// 						member
// 						isConnected
// 						shortDescription
// 						statusLink
// 						documentationLinks {
// 							id
// 							name
// 							link
// 						}
// 						videoLinks {
// 							id
// 							name
// 							link
// 						}
// 						bookLinks {
// 							id
// 							name
// 							link
// 						}
// 						businessOwner {
// 							id
// 							name
// 							email
// 						}
// 						tool {
// 							id
// 							icon
// 							name
// 							description
// 							shortDescription
// 							documentationLinks {
// 								name
// 								link
// 							}
// 							businessOwner
// 							toolLink
// 							statusLink
// 							health
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// `;

const loginQuery = gql`
	query login($loginInput: LoginInput!) {
		login(loginInput: $loginInput) {
			accessToken
		}
	}
`;

// const toolQuery = gql`
// 	query tools($skip: Int, $name: String, $take: Int) {
// 		tools(filterOptions: { name: $name }, skip: $skip, take: $take) {
// 			totalCount
// 			list {
// 				id
// 				name
// 				icon
// 				description
// 				shortDescription
// 				documentationLinks {
// 					name
// 					link
// 				}
// 				businessOwner
// 				toolLink
// 				statusLink
// 				dateAdded
// 			}
// 		}
// 	}
// `;

const teamQuery = gql`
	query team($id: String!) {
		id
		slug
		name
		addedBy
		# tools {
		# 	tool {
		# 		icon
		# 		id
		# 		name
		# 		description
		# 		shortDescription
		# 		documentationLinks {
		# 			name
		# 			link
		# 		}
		# 		businessOwner
		# 		toolLink
		# 		statusLink
		# 	}
		# }
	}
`;

const getUsersQuery = gql`
	query users($take: Int, $skip: Int, $name: String) {
		users(take: $take, skip: $skip, filterOptions: { name: $name }) {
			totalCount
			list {
				id
				email
				name
				profilePhoto
				lastLogin
			}
		}
	}
`;

const teamsQuery = gql`
	query getTeams($skip: Int, $take: Int, $prefix: String) {
		getTeams(skip: $skip, take: $take, prefix: $prefix) {
			totalCount
			teams {
				totalTeamCount
				id
				hearAboutUs
				createdDate
				name
				logos
				#tools {
				#	totalCount
				#}
				noOfTeamProjectsCount {
					type
					count
				}
				noOfTeamProjects
				projects {
					totalCount
				}
				users {
					totalCount
					list {
						userTeamRole {
							name
						}
						user {
							email
							lastLogin
						}
					}
				}
			}
		}
	}
`;

const usersQuery = gql`
	query allUsers($skip: Int, $take: Int) {
		allUsers(skip: $skip, take: $take) {
			totalCount
			teamUsers {
				userCount
				id
				user {
					id
					name
					email
					createdDate
					profilePhoto
					lastLogin
					active
					isConfirmed
				}
				userTeamRole {
					name
				}
				team {
					id
					name
				}
			}
		}
	}
`;

// const toolsQuery = gql`
// 	query getTools($skip: Int, $take: Int) {
// 		getTools(skip: $skip, take: $take) {
// 			name
// 			icon
// 			shortDescription
// 			health
// 			description
// 			toolLink
// 			twitter
// 			dateAdded
// 			isIntegrated
// 			documentationLinks {
// 				id
// 				name
// 				link
// 			}
// 			videoLinks {
// 				id
// 				name
// 				link
// 			}
// 			bookLinks {
// 				id
// 				name
// 				link
// 			}
// 		}
// 	}
// `;

// const getTeamKeepsQuery = gql`
// 	query getTeamKeeps($skip: Int, $take: Int, $teamName: String ) {
// 		getTeamKeeps(skip: $skip, take: $take, teamName:$teamName ){
//     	value
//     	id
//   }
// 	}
// `;

// const getUserKeepsQuery = gql`
// 	query getUserKeeps($skip: Int, $take: Int,) {
// 		getUserKeeps(skip: $skip, take: $take,  ){
// 		id
//     	team{
//       	keeps(id: $id){
//         totalCount
//       }
//     }
//   }
// 	}
// `;

// const getTeamsKeepQuery = gql`
// 	query generalKeeps($take: Int, $skip: Int, $title: String) {
// 		generalKeeps(take: $take, skip: $skip, filterOptions: { title: $title }) {
// 			totalCount
// 			list {
// 				id
// 				title
// 				key
// 				description
// 				type
// 				member
// 				sendEmail
// 				recommendations {
// 					totalCount
// 					list {
// 						id
// 						# title
// 						note
// 						url
// 						key
// 						updatedDate
// 						recommendedBy {
// 							id
// 							name
// 						}
// 					}
// 				}
// 				userKeeps {
// 					totalCount
// 					list {
// 						id
// 						user {
// 							id
// 						}
// 					}
// 				}
// 				password
// 				url
// 				username
// 				document
// 				businessOwner {
// 					id
// 					name
// 					email
// 				}
// 				createdDate
// 				updatedDate
// 				keepNotes {
// 					totalCount
// 					list {
// 						id
// 						note
// 						createdDate
// 						updatedDate
// 					}
// 				}
// 			}
// 		}
// 	}
// `;

const waitlistQuery = gql`
	query getWaitlist($skip: Int, $take: Int, $prefix: String) {
		getWaitlist(skip: $skip, take: $take, prefix: $prefix) {
			totalCount
			waitlist {
				id
				email
				createdDate
			}
		}
	}
`;

const getAdminsQuery = gql`
	query teamAdmin {
		teamAdmin {
			id
			user {
				id
				name
			}
		}
	}
`;

const checkIfUserIsRegisteredQuery = gql`
	query checkIfUserIsRegistered($userEmail: String!) {
		checkIfUserIsRegistered(userEmail: $userEmail)
	}
`;

const getBlogsQuery = gql`
	query getBlogs($skip: Int, $take: Int) {
		getBlogs(skip: $skip, take: $take) {
			totalCount
			list {
				id
				image
				tag
				title
				author
				dateAdded
				content
				description
				slug
			}
		}
	}
`;

const blogEmailsQuery = gql`
	query getAllSubscribedEmails($skip: Int, $take: Int) {
		getAllSubscribedEmails(skip: $skip, take: $take) {
			totalCount
			list {
				id
				email
				createdDate
			}
		}
	}
`;

const getBlogQuery = gql`
	query getBlog($slug: String!) {
		getBlog(slug: $slug) {
			id
			description
			content
			author
			dateAdded
			tag
			image
			title
			slug
		}
	}
`;

const getDemosQuery = gql`
	query getDemos($skip: Int, $take: Int) {
		getDemos(skip: $skip, take: $take) {
			totalCount
			list {
				id
				name
				email
				createdDate
			}
		}
	}
`;

const getAnalyticsDataQuery = gql`
	query getAnalyticsData($skip: Int, $take: Int) {
		getAnalyticsData(skip: $skip, take: $take) {
			totalCount
			data {
				id
				currentDay
				count
				users
				team
				teamCount
				createdDate
			}
		}
	}
`;

const getSingleAnalyticsDataQuery = gql`
	query getSingleAnalyticsData($id: String!) {
		getSingleAnalyticsData(id: $id) {
			id
			count
			currentDay
			users
			team
		}
	}
`;

const getUsersGrowthAnalyticsDataQuery = gql`
	query getUsersGrowthAnalyticsData($interval: Int!) {
		getUsersGrowthAnalyticsData(interval: $interval) {
			data {
				key
				value
			}
		}
	}
`;

export {
	getUsersGrowthAnalyticsDataQuery,
	getSingleAnalyticsDataQuery,
	getAnalyticsDataQuery,
	getDemosQuery,
	getBlogQuery,
	blogEmailsQuery,
	getBlogsQuery,
	checkIfUserIsRegisteredQuery,
	getAdminsQuery,
	getUsersQuery,
	// currentToolsFilteredQuery,
	currentTeamTeammateFilteredQuery,
	userQuery,
	loginQuery,
	// toolQuery,
	teamQuery,
	currentTeamTeammateQuery,
	// currentTeamToolsQuery,
	currentConfirmedTeamTeammateQuery,
	// currentToolsSharableFilteredQuery,
	currentUserQuery,
	teamsQuery,
	usersQuery,
	waitlistQuery,
	// toolsQuery
	// getUserKeepsQuery,
	// getTeamsKeepQuery
	// getTeamKeepsQuery,
};
