<template>
	<div class="flex justify-center p-4 text-center mx-auto">
		<div
			class="px-4 py-5 transform transition-all sm:max-w-sm sm:w-full sm:p-6 sm:rounded-md shadow-xs bg-white"
			role="dialog"
			aria-modal="true"
			aria-labelledby="modal-headline"
		>
			<div>
				<div class="flex items-center justify-center">
					<svg
						class="flex-shrink-0 -ml-1 mr-3 h-12 w-12 text-red-500 transition ease-in-out duration-150"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
						/>
					</svg>
				</div>
				<div class="mt-3 text-center sm:mt-5">
					<h3 class="text-lg leading-6 font-medium" id="modal-headline">
						{{
							online === null || online === true
								? "Sorry, this is unusual. We are investigating!"
								: "Please check your internet connection"
						}}
					</h3>
					<div v-if="online === null || online === true" class="mt-2">
						<p class="text-sm leading-5 text-gray-500">
							{{
								message === "Failed to fetch"
									? "Please check your internet connection"
									: "Internal server error"
							}}
						</p>
					</div>
				</div>
			</div>
			<div class="mt-5 sm:mt-6">
				<span class="flex w-full justify-center">
					<button
						type="button"
						@click="reload"
						class="relative inline-flex items-center px-4 py-2 border border-gray-200 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
					>
						Reload
					</button>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
	name: "Errorr",

	props: {
		message: String,
	},

	setup() {
		const store = useStore();
		const online = computed(() => store.state.online);
		const reload = () => window.location.reload();

		return { online, reload };
	},
};
</script>
