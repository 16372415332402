<template>
	<div ref="customDropdown" class="relative">
		<span @click="showOptions" class="inline-block rounded-md">
			<button>
				<slot name="dropdownIcon">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						class="h-5 w-5 text-gray-400"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"
						/>
					</svg>
				</slot>
			</button>
		</span>
		<div
			v-if="options"
			@click="showOptions"
			class="w-64 text-sm z-50 absolute top-full mt-2 right-0 overflow-auto rounded-md shadow-sm bg-white border border-gray-100 min-w-full"
		>
			<slot name="items"> No Items </slot>
		</div>
	</div>
</template>

<script>
import { defineComponent, reactive, ref, toRefs } from "@vue/runtime-core";
import useDetectOutsideClick from "../../composables/useClickOutside";

export default defineComponent({
	name: "Custom dropdown",
	props: {
		list: Array,
		addMoreText: String,
		align: String,
		admin: Boolean,
		isMaintainer: Boolean,
		dropdownIcon: Object,
		bgStyles: String,
		teams: Boolean,
	},
	emits: ["select-option"],
	setup(props, { emit }) {
		const state = reactive({
			options: false,
		});

		const showOptions = () => (state.options = !state.options);
		const hideOptions = () => (state.options = false);
		const choose = (val) => {
			if (val !== null) emit("select-option", val);
			hideOptions();
		};
		const customDropdown = ref();
		useDetectOutsideClick(customDropdown, hideOptions);

		return { ...toRefs(state), showOptions, hideOptions, choose, customDropdown };
	},
});
</script>

<style lang="scss" scoped></style>
