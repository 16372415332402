import { createRouter, createWebHistory } from "vue-router";
import ProtectedRoutes from "@/views/protected";
// import store from "@/store/index";

const allowedRoutes = [
	"login",
	"forgotpassword",
	"recoverpasswithcode",
	"sharetools",
	"errorpage",
	"awaitconfirm",
	"confirmemailpagelogin",
];

const baseURLs = {
	"http://localhost:8080/": true,
	"https://devapp.onboardbase.com/": true,
	"https://devadmin.onboardbase.com/": true,
	"https://admin.onboardbase.com/": true,
	"https://app.onboardbase.com/": true,
	"https://app.onboardbase.com/login": true,
	"https://devapp.onboardbase.com/login": true,
	"https://devadmin.onboardbase.com/login": true,
	"https://admin.onboardbase.com/login": true,
	"http://localhost:8080/login": true,
	"http://localhost:8081/login": true,
	"http://localhost:8081": true,
};
const redirecturlal = window.location.href;

const routes = [
	...ProtectedRoutes,
	{
		path: "/:catchAll(.*)",
		component: () => import("@/views/protected/Error.vue"),
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	// store.commit("setOrgDropdown", false);
	// store.commit("setSetupDropdown", false);
	const token = localStorage.getItem("o-token-ap");
	if (
		(!allowedRoutes.includes(to.name) && token === null) ||
		(token === "null" && token === undefined && token === "undefined" && token.trim() === "")
	) {
		const params = { name: "login" };
		!redirecturlal.includes("redirecturlal") &&
			from.name !== "login" &&
			!baseURLs[redirecturlal] &&
			(params.query = { redirecturlal });
		next(params);
	} else {
		next();
	}
});

export default router;
