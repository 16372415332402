<template>
	<div class="bg-white mt-5 border border-gray-100 rounded-lg">
		<div class="flex flex-col">
			<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div class="align-middle inline-block min-w-full w-full">
					<div class="overflow-hidden sm:rounded-lg">
						<div class="bg-white justify-between flex px-4 py-5 border-b border-gray-100 sm:px-6">
							<div class="">
								<h3 class="text-lg leading-6 font-medium text-gray-900">Payment Tiers</h3>
								<p class="mt-1 text-sm leading-5 text-gray-500">List of Payment Tiers</p>
							</div>
							<div class="flex">
								<div class="ml-4 flex-shrink-0">
									<span class="inline-flex rounded-md">
										<a href="#" class="flex justify-center">
											<span
												class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-ob-purple-color hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700"
												@click.prevent="usageBilling"
												>Update Tier</span
											>
										</a>
									</span>
								</div>
							</div>
						</div>
						<error v-if="error && !loading" />

						<table v-if="!error" class="min-w-full divide-y divide-gray-100">
							<thead>
								<tr>
									<th
										class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
									>
										Tiers
									</th>
									<th
										class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
									>
										Pricing
									</th>

									<th
										class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
									>
										Secret Limit
									</th>
								</tr>
							</thead>

							<tbody class="bg-white divide-y divide-gray-100">
								<tr v-for="tier in tiers" :key="tier.id" class="hover:bg-gray-100">
									<td class="px-6 py-4 whitespace-no-wrap">
										<div class="flex items-center">
											<div
												class="flex-shrink-0 h-10 w-10 uppercase bg-ob-purple-color text-white overflow-hidden rounded-md font-bold flex justify-center items-center"
											>
												{{ tier.tier[0] }}
											</div>
											<div class="ml-4">
												<div class="text-sm leading-5 capitalize font-medium text-gray-900">
													{{ tier.tier }}
												</div>
											</div>
										</div>
									</td>
									<td class="px-6 py-4 whitespace-no-wrap">
										<div class="flex items-center">
											<div class="ml-4">
												<div class="text-sm leading-5 capitalize font-medium text-gray-900">
													{{ tier.pricePerUser }}
												</div>
											</div>
										</div>
									</td>
									<td class="px-6 py-4 whitespace-no-wrap">
										<div class="flex items-center">
											<div class="ml-4">
												<div class="text-sm leading-5 capitalize font-medium text-gray-900">
													{{ tier.secretProcessLimit }}
												</div>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
						<div v-if="loading">
							<list-skeleton />
							<list-skeleton />
							<list-skeleton />
							<list-skeleton />
							<list-skeleton />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import Error from "./Error.vue";
import { autoPick } from "../utils";
import { useQuery } from "@vue/apollo-composable";
import ListSkeleton from "./skeleton/ListSkeleton.vue";
import { listAllPaymentTiersQuery } from "../graphql/billing/query";

export default {
	components: { Error, ListSkeleton },
	name: "Usage",

	setup() {
		const store = useStore();

		const { result, loading, error } = useQuery(listAllPaymentTiersQuery);
		const tiers = computed(() => autoPick(result.value));

		const usageBilling = () => {
			store.commit("setUpdateTierModal", {
				state: true,
				forCreate: true,
				title: "Update Usage Billing",
			});
		};

		return {
			store,
			tiers,
			error,
			loading,
			usageBilling,
		};
	},
};
</script>
