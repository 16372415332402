<template>
	<div class="relative overflow-hidden">
		<img
			class="home-middleimage left-0 right-0 w-full absolute"
			src="@/assets/img/middle-image.svg"
			alt="Middle"
		/>

		<div class="relative">
			<div class="min-h-screen relative flex flex-col justify-center py-12 sm:px-6 lg:px-8">
				<div class="px-4 sm:mx-auto sm:w-full sm:max-w-md">
					<router-link to="/">
						<img
							class="mx-auto h-8 md:h-10 w-auto"
							src="@/assets/img/new-logo-dark.png"
							alt="Onboardbase Logo"
						/>
					</router-link>
					<h2 class="mt-6 text-center text-xl md:text-3xl leading-9 font-extrabold">
						Login in to admin
					</h2>
					<p class="mt-2 text-center text-sm leading-5 font-medium text-gray-500 max-w">
						Welcome back :)
					</p>
				</div>
				<div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
					<div class="bg-white py-8 px-4 shadow-xs sm:rounded-lg sm:px-10">
						<form @submit.prevent="handleLogin">
							<div>
								<label for="email" class="block text-sm font-medium leading-5 text-gray-700">
									Email address
								</label>
								<div class="mt-1 rounded-md">
									<input
										id="email"
										type="email"
										required
										v-model.trim="email"
										class="appearance-none block w-full px-3 py-2 border border-gray-100 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
									/>
								</div>
							</div>

							<!-- <div class="mt-6">
								<label for="password" class="block text-sm font-medium leading-5 text-gray-700">
									Password
								</label>
								<div class="mt-1 rounded-md relative">
									<div class="absolute inset-y-0 right-0 px-3 py-3">
										<div @click="show" class="cursor-pointer">
											<svg
												v-if="showVal === 'password'"
												class="flex-shrink-0 h-4 w-4 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
												fill="currentColor"
											>
												<path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
												<path
													fill-rule="evenodd"
													d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
													clip-rule="evenodd"
												/>
											</svg>
											<svg
												v-else
												class="flex-shrink-0 h-4 w-4 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
												fill="currentColor"
											>
												<path
													fill-rule="evenodd"
													d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
													clip-rule="evenodd"
												/>
												<path
													d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z"
												/>
											</svg>
										</div>
									</div>
									<input
										id="password"
										:type="showVal"
										required
										v-model="password"
										class="appearance-none block w-full px-3 py-2 border border-gray-100 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
									/>
								</div>
							</div> -->

							<!-- <div class="mt-6 flex items-center justify-end md:flex-row flex-col">

								<div class="text-sm leading-5 mt-4 md:mt-0">
									<router-link
										to="/forgotpassword"
										class="font-medium text-ob-purple-color hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
									>
										Forgot your password?
									</router-link>
								</div>
							</div> -->

							<div class="mt-6">
								<span class="block w-full rounded-md shadow-sm">
									<button
										type="submit"
										:disabled="loginQueryLoading"
										:class="loginQueryLoading && 'opacity-25 pointer-events-none'"
										class="w-full .opacity-50 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-ob-purple-color hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
									>
										{{ loginQueryLoading ? "Continuing..." : "Continue with Email" }}
									</button>
								</span>
							</div>
						</form>
					</div>
					<div class="px-4 py-6 sm:px-10">
						<p class="text-xs leading-5 text-center text-gray-500">
							By signing in, you agree to our
							<a href="https://onboardbase.com/terms" class="font-semibold hover:underline"
								>Terms</a
							>
							and
							<a href="https://onboardbase.com/privacy" class="font-semibold hover:underline"
								>Privacy</a
							>.
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- <button @click="enableQuery">Click Me</button> -->
</template>

<script>
import { useQuery, useResult } from "@vue/apollo-composable";
import { loginQuery } from "@/graphql/query";
// import Errorr from "@/components/alerts/Error.vue";
// import Success from "@/components/alerts/Success.vue";
import { onUnmounted, ref, watchEffect } from "vue";
import { useStore } from "vuex";
import { handleGlobalAlert } from "../../../components/connectButtons/shared";
import { useRouter } from "vue-router";
// import jwt_decode from "jwt-decode";

export default {
	name: "Login",
	components: {
		// error: Errorr,
		// success: Success,
	},

	setup() {
		const store = useStore();
		// Form info bound to form input
		const email = ref("");
		const password = ref("");
		const rememberMe = ref(false);
		const router = useRouter();
		// const route = useRoute();

		// State that enable/disable (true/false) query to server
		const enabled = ref(false);

		onUnmounted(() => {
			enabled.value === false;
		});

		// Login Query
		const {
			result: loginResult,
			loading: loginQueryLoading,
			error: loginError,
		} = useQuery(loginQuery, { loginInput: { email: email } }, () => ({
			enabled: enabled.value,
		}));

		const res = useResult(loginResult, null, (data) => data.login);
		watchEffect(() => {
			if (loginQueryLoading.value === false && res.value && !loginError.value) {
				store.commit("setLoginEmail", email.value);
				router.push({ path: "/await-confirm" });

				// const user = res.value.user;
				// /// Save token to local storage
				// if (res.value.accessToken) {
				// 	localStorage.setItem("o-token-ap", res.value.accessToken);
				// 	store.commit("setToken", res.value.accessToken);
				// }
				// user && store.commit("setUser", user);

				// if (user.isConfirmed === true) {
				// 	if(user.role?.name.toLowerCase() === 'admin') {
				// 		router.push({ path: "/" });
				// 		handleGlobalAlert(true, "success", `Welcome to Onboard`);
				// 	}
				// 	else {
				// 		enabled.value = false;
				// 		handleGlobalAlert(true, "failed", "Unauthorized Access");
				// 	}

				// } else {
				// 	handleGlobalAlert(true, "success", `Confirm your email`);
				// 	router.push({ path: "/confirmemail" });
				// }
			} else if (loginQueryLoading.value === false && loginError.value) {
				enabled.value = false;
				handleGlobalAlert(true, "failed", "Please try again");
			}
		});

		// Function that enables login .....
		function enableQuery() {
			store.commit("setFromLogin", true);
			enabled.value = true;
		}

		return {
			enableQuery,
			password,
			email,
			rememberMe,
			loginQueryLoading,
		};
	},

	data() {
		const showVal = "password";

		return {
			showVal,
		};
	},

	methods: {
		handleLogin() {
			// if (this.password.length < 6) {
			// 	this.$store.commit("setGlobalAlert", {
			// 		show: true,
			// 		type: "failed",
			// 		text: "Password length must be atleast 6"
			// 	});
			// 	return;
			// }
			this.enableQuery();
		},

		show() {
			this.showVal = this.showVal === "password" ? "text" : "password";
		},
	},
};
</script>
