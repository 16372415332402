<template>
	<div class="relative overflow-hidden">
		<img
			class="home-middleimage left-0 right-0 w-full absolute"
			src="@/assets/img/middle-image.svg"
			alt="Middle"
		/>
		<div class="relative">
			<div class="error h-screen flex justify-center content-center">
				<div
					class="w-1/3 flex flex-col content-center justify-center mx-auto md:py-64 text-center md:px-10 py-6"
				>
					<div class="">
						<router-link to="/">
							<img
								class="mx-auto h-10 w-auto mb-10"
								src="@/assets/img/new-logo-dark.png"
								alt="Onboardbase Logo"
							/>
						</router-link>
						<p
							class="md:text-base text-sm font-semibold text-ob-purple-color uppercase tracking-wide"
						>
							404 error
						</p>
						<div class="lg:text-5xl md:text-4xl text-2xl font-bold leading-tight">
							Page not found.
						</div>
						<div class="text-base text-gray-500 mt-2 w-5/6 mx-auto">
							Sorry, the page your are looking for doesn't exist or has been removed
						</div>
					</div>
					<div class="mt-6 w-2/5 mx-auto">
						<router-link to="/">
							<span
								class="rounded block w-full px-5 py-3 text-center font-medium text-white bg-ob-purple-color hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
								>Home</span
							>
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Error",
};
</script>
