<template>
	<div v-if="loading" class="fixed h-screen w-screen overflow-hidden bg-dark-color">
		<div class="relative">
			<div class="error h-screen flex justify-center content-center">
				<div
					class="w-2/5 flex flex-col content-center justify-center mx-auto md:py-64 text-center md:px-10 py-6"
				>
					<div class="">
						<router-link to="/">
							<img
								class="mx-auto h-10 w-auto mb-6"
								src="@/assets/img/new-logo.png"
								alt="Onboardbase Logo"
							/>
						</router-link>
						<div class="md:text-xl text-gray-400 leading-tight">Please wait...</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
	name: "Loading",

	setup() {
		const store = useStore();
		const loading = computed(() => store.state.loading);

		return { loading };
	},
};
</script>
