<template>
	<div class="relative overflow-hidden">
		<img
			class="home-middleimage left-0 right-0 w-full absolute"
			src="@/assets/img/middle-image.svg"
			alt="Middle"
		/>

		<div class="relative">
			<div class="min-h-screen relative flex flex-col justify-center py-12 sm:px-6 lg:px-8">
				<div class="px-4 sm:mx-auto sm:w-full sm:max-w-lg">
					<router-link to="/">
						<img
							class="mx-auto h-8 md:h-10 w-auto"
							src="@/assets/img/new-logo-dark.png"
							alt="Onboardbase logo"
						/>
					</router-link>
					<h2 class="mt-6 text-center text-xl md:text-3xl leading-9 font-extrabold">
						Awaiting Confirmation
					</h2>
					<p class="mt-2 text-center text-sm leading-5 text-gray-500">
						Do not close/refresh this window until opening the email link.
					</p>
				</div>

				<div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
					<div class="bg-white py-5 px-4 shadow-xs rounded-lg">
						<div
							:class="loginQueryLoading && 'pointer-events-none'"
							class="text-center text-gray-500 text-sm"
						>
							<p>
								We just sent an email to
								<span class="text-gray-800 font-semibold">{{ email }}</span
								>.
							</p>
							<p class="mt-0.5">Click the link sent to you to complete login</p>
							<div
								v-if="email"
								@click="resendLink"
								class="p-2 mt-2 text-ob-purple-color font-semibold cursor-pointer"
							>
								{{ loginQueryLoading ? "Sending..." : "Resend link" }}
							</div>
						</div>
					</div>
					<div class="px-4 py-6 sm:px-10">
						<p class="text-xs leading-5 text-center text-gray-500">
							By signing up, you agree to our
							<a
								href="https://onboardbase.com/terms"
								target="_blank"
								class="font-semibold hover:underline"
								>Terms</a
							>
							and
							<a
								href="https://onboardbase.com/privacy"
								target="_blank"
								class="font-semibold hover:underline"
								>Privacy</a
							>.
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { loginQuery } from "../../../graphql/query";
import { useQuery } from "@vue/apollo-composable";
import { useRouter } from "vue-router";
import { handleGlobalAlert } from "../../../components/connectButtons/shared";

export default {
	name: "Await confirmation",
	setup() {
		const store = useStore();
		const router = useRouter();
		const email = computed(() => store.state.loginEmail);
		const enabled = ref(false);

		const { onResult, loading: loginQueryLoading } = useQuery(
			loginQuery,
			{ loginInput: { email: email.value } },
			() => ({
				enabled: enabled.value,
				fetchPolicy: "network-only",
			})
		);
		onResult((queryResult) => {
			if (queryResult.data) {
				if (!queryResult.data.login.enabledTwoFactor) {
					store.commit("setLoginEmail", email.value);
					handleGlobalAlert(true, "success", "Sent!");
				} else {
					localStorage.setItem("temp-token", queryResult.data.login.temporaryAuthToken);
					router.push("/2fa");
				}
			} else {
				handleGlobalAlert(true, "failed", queryResult.error.message);
			}
			enabled.value = false;
		});

		const resendLink = () => {
			enabled.value = true;
		};

		return { email, loginQueryLoading, resendLink };
	},
};
</script>
