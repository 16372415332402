<template>
	<div class="flex items-end justify-center pt-4 px-4 pb-20 text-center">
		<div
			class="inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
			role="dialog"
			aria-modal="true"
			aria-labelledby="modal-headline"
		>
			<div>
				<div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100">
					<svg
						class="h-6 w-6 text-ob-purple-color"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fill-rule="evenodd"
							d="M2 5a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm14 1a1 1 0 11-2 0 1 1 0 012 0zM2 13a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2zm14 1a1 1 0 11-2 0 1 1 0 012 0z"
							clip-rule="evenodd"
						/>
					</svg>
				</div>
				<div class="mt-3 text-center sm:mt-5">
					<h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
						{{ message.text }}
					</h3>
					<div class="mt-2">
						<p class="text-sm leading-5 text-gray-500">
							{{
								message.description
									? message.description
									: "It's possible this content hasn't been created or synced yet."
							}}
						</p>
					</div>
				</div>
			</div>
			<div v-if="message.button" class="mt-5 sm:mt-6">
				<span class="flex w-full rounded-md">
					<router-link
						:to="`/team/${currentTeam}/${message.route}`"
						class="inline-flex w-full justify-center items-center px-4 py-2 border border-transparent shadow-sm text-base leading-6 font-medium rounded-md text-white bg-ob-purple-color hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700"
					>
						{{ message.button }}
					</router-link>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
	name: "Empty",

	props: {
		message: Object,
	},

	setup() {
		const store = useStore();
		const currentTeam = computed(() => store.state.currentTeam.slug);
		return { currentTeam };
	},
};
</script>
