<template>
	<div class="app antialiased">
		<global-alert class="z-50" />
		<!-- <server-error /> -->
		<!-- <error v-if="error" /> -->
		<internet-status-popup />
		<loading class="up" />
		<router-view />
	</div>
</template>

<script>
import GlobalAlert from "@/components/alerts/GlobalAlert";
import Loading from "@/components/Loading";
import { onMounted, onUnmounted } from "vue";
import { checkOnlineStatus } from "@/utils/internetStatus";
import InternetStatusPopup from "./components/alerts/InternetStatusPopup.vue";
// import ServerError from "./views/protected/ServerError.vue";
// import Error from "./components/Error.vue";

export default {
	name: "app",

	components: {
		globalAlert: GlobalAlert,
		loading: Loading,
		InternetStatusPopup,
		// ServerError
		// Error
	},

	setup() {
		let timer;
		const handleOnlineCheck = () => (timer = setInterval(() => checkOnlineStatus(), 800));
		onMounted(() => handleOnlineCheck());
		onUnmounted(() => clearInterval(timer));
	},
};
</script>

<style lang="scss">
.up {
	z-index: 100000;
}
</style>
