import { gql } from "@apollo/client";

const typeDefs = gql`
	type FilterOptionsInput {
		name: String
	}

	type LoginInput {
		email: String!
		password: String!
	}

	type UserInput {
		name: String!
		profilePhoto: String
		title: String
		password: String
		confirmPassword: String
		tourGuide: String
		phoneNo: String
		location: String
		# time: DateTime
		zone: String
	}

	type LoginResponse {
		accessToken: String!
	}
	type SyncResponse {
		status: Boolean!
		lastSynced: String!
	}

	type UserModel {
		id: ID!
		email: String!
		name: String!
		role: RoleModel!
		active: Boolean
		lastLogin: DateTime
		teams: [TeamModel!]!
		createdDate: DateTime!
		updatedDate: DateTime!
	}

	type RoleModel {
		id: ID!
		name: String!
	}

	type PasswordResetInput {
		oldPassword: String!
		newPassword: String!
		newPasswordConfirmation: String!
	}

	type UsersResponse {
		totalCount: Int!
		list: [UserModel!]!
	}

	type TeamModel {
		id: ID!
		name: String!
		addedBy: UserModel!
	}

	type addEmployeeMutation {
		email: String!
		name: String!
	}

	type AddEmployeeInput {
		# name: String!
		email: String!
	}

	type UpdateToolInput {
		id: String!
		sharable: Boolean
		businessOwner: String
		businessUsecase: String
		shortDescription: String
		statusLink: String
	}

	type DisconnectResponse {
		status: Boolean!
		tool: TeamToolModel
	}

	type TeamToolsPropsResponse {
		domain: String!
	}
	type UpdateTeamInput {
		id: ID!
		name: String
		logos: String
	}

	type DocumentationLinkInput {
		id: String
		name: String
		link: String
	}

	type VideoLinkInput {
		id: String
		name: String
		link: String
	}

	type BookLinkInput {
		id: String
		name: String
		link: String
	}

	type UpdateToolPropsInput {
		DocumentationLinkInput: [DocumentationLinkInput]
		VideoLinkInput: [VideoLinkInput]
		BookLinkInput: [BookLinkInput]
	}

	type UpdateTeamUserInput {
		id: String!
		location: String
		reportTo: String
		key: String
		hidden: Boolean
	}
`;

export default typeDefs;
