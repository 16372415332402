<template>
	<transition name="fadeIn">
		<div class="fixed z-50 inset-0 overflow-y-auto">
			<div
				class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
			>
				<div class="fixed inset-0 transition-opacity" aria-hidden="true">
					<div
						@click.self="closeModal"
						class="absolute inset-0 bg-gray-800 bg-opacity-75 modal-blur"
					></div>
				</div>
				<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"
					>&#8203;</span
				>

				<div
					class="main inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
					role="dialog"
					aria-modal="true"
					aria-labelledby="modal-headline"
				>
					<div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
						<button
							@click="closeModal"
							type="button"
							class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
						>
							<span class="sr-only">Close</span>
							<svg
								class="h-6 w-6"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
					<div class="sm:flex flex-wrap sm:items-start w-full">
						<div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
							<h3 class="text-lg leading-6 font-medium mb-4" id="modal-headline">
								{{ show.title }}
								<!-- title -->
							</h3>
							<div class="my-4 w-full">
								<label for="couponCode" class="block text-sm font-medium leading-5 text-gray-700">
									Coupon Code
								</label>
								<div class="mt-1 rounded-md">
									<input
										id="couponCode"
										placeholder="Coupon Code"
										class="form-input block w-full border border-gray-200 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
									/>
								</div>
							</div>

							<div class="my-4 w-full">
								<label for="dueDate" class="block text-sm font-medium leading-5 text-gray-700">
									Due Date
								</label>
								<div class="mt-1 rounded-md">
									<input
										v-model="dueDate"
										id="dueDate"
										type="date"
										placeholder="Due Date"
										class="form-input block w-full border border-gray-200 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
									/>
								</div>
							</div>
							<div class="my-4 w-full">
								<label
									for="maxRedemptions"
									class="block text-sm font-medium leading-5 text-gray-700"
								>
									Maximum Redemptions
								</label>
								<div class="mt-1 rounded-md">
									<input
										v-model.number="maxRedemptions"
										id="maxRedemptions"
										placeholder="Maximum Redemptions"
										class="form-input block w-full border border-gray-200 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
									/>
								</div>
							</div>
							<div class="my-4 w-full">
								<label
									for="discountPercentage"
									class="block text-sm font-medium leading-5 text-gray-700"
								>
									Discount Percentage
								</label>
								<div class="mt-1 rounded-md">
									<input
										v-model.number="discountPercentage"
										id="discountPercentage"
										placeholder="Discount Percentage"
										class="form-input block w-full border border-gray-200 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
									/>
								</div>
							</div>
						</div>
					</div>

					<div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
						<button
							class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
						>
							Updating
						</button>
						<button
							@click="closeModal"
							type="button"
							class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-100 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
						>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
	name: "CouponModal",
	setup() {
		const store = useStore();
		const show = computed(() => store.state.couponModal);

		const closeModal = () => {
			store.commit("setCouponModal", { state: false });
		};

		return {
			show,
			closeModal,
		};
	},
};
</script>

<style lang="scss" scoped>
.fadeIn-enter-active,
.fadeIn-leave-active {
	.main {
		transition: all 0.3s ease-in-out;
	}
}
.fadeIn-leave-to,
.fadeIn-enter-from {
	.main {
		transform: scale(0.8);
		opacity: 0;
	}
}
</style>
