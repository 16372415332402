import { handleGlobalAlert } from "../components/connectButtons/shared";

const copyText = (text) => {
	const el = document.createElement("textarea");
	el.textContent = text;
	document.body.appendChild(el).select();
	document.execCommand("copy");
	document.body.removeChild(el);
	handleGlobalAlert(true, "success", "Copied");
};

export default copyText;
