import { gql } from "@apollo/client";

export const getTeamsWithEnterprisePriceQuery = gql`
	query getTeamsWithEnterprisePrice($name: String, $skip: Int, $take: Int) {
		getTeamsWithEnterprisePrice(name: $name, skip: $skip, take: $take) {
			totalCount
			list {
				id
				perUser
				status
				isActive
				paymentTier {
					tier
					secretProcessLimit
				}
				team {
					id
					name
					slug
				}
				renewalDate
				interval
				enterprisePrice
				enterpriseSecretProcessLimit
			}
		}
	}
`;

export const getOrganizationsWithPlanFilterQuery = gql`
	query getOrganizationsWithPlanFilter($teamsWithPlanInput: TeamsWithPlanInput) {
		getOrganizationsWithPlanFilter(teamsWithPlanInput: $teamsWithPlanInput) {
			totalCount
			list {
				id
				name
				slug
				paymentTier {
					tier
					secretProcessLimit
					pricePerUser
				}
				addedBy {
					name
					email
				}
				teamPaymentTier {
					id
					perUser
					status
					isActive
					rawRenewalDate
					interval
					enterprisePrice
					enterpriseSecretProcessLimit
					totalSecretsLimit
					notPaying
					subscriptionSession {
						secretProcessed
					}
				}
			}
		}
	}
`;

export const listAllPaymentTiersQuery = gql`
	query listAllPaymentTiers {
		listAllPaymentTiers {
			id
			tier
			pricePerUser
			secretProcessLimit
		}
	}
`;
