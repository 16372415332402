import { gql } from "@apollo/client";

const resetPasswordWithVerificationCodeMutation = gql`
	mutation resetPasswordWithVerificationCode(
		$confirmPassword: String!
		$password: String!
		$code: String!
	) {
		resetPasswordWithVerificationCode(
			confirmPassword: $confirmPassword
			password: $password
			code: $code
		) {
			id
			email
			name
		}
	}
`;

const forgotPasswordMutation = gql`
	mutation recoverPassword($email: String!) {
		recoverPassword(email: $email)
	}
`;

const updateUserProfileMutation = gql`
	mutation updateUserProfile($userInput: UserInput!) {
		updateUserProfile(userInput: $userInput) {
			name
			id
			email
			createdDate
			isConfirmed
			profilePhoto
			title
			zone
			location
			phoneNo
			time
			role {
				name
			}
			defaultTeam {
				id
				name
				slug
				logos
				shareCode
				createdDate
				users {
					totalCount
					list {
						id
						user {
							id
							name
							email
							isConfirmed
							profilePhoto
							createdDate
							title
							role {
								name
							}
						}
					}
				}
			}
		}
	}
`;

const changePassword = gql`
	mutation resetPassword($passwordResetInput: PasswordResetInput!) {
		resetPassword(passwordResetInput: $passwordResetInput) {
			name
			id
			email
			role {
				name
			}
			isConfirmed
		}
	}
`;

// const addToolToTeamMutation = gql`
// 	mutation addTools($ids: [String!]!) {
// 		addTools(toolIds: $ids) {
// 			id
// 			sharable
// 			tool {
// 				description
// 				shortDescription
// 				id
// 				name
// 				icon
// 			}
// 		}
// 	}
// `;

// const removeToolFromTeamMutation = gql`
// 	mutation removeTools($ids: [String!]!) {
// 		removeTools(toolIds: $ids) {
// 			# id
// 			sharable
// 			tool {
// 				id
// 				name
// 				icon
// 				description
// 			}
// 		}
// 	}
// `;

const addTeamMutation = gql`
	mutation addTeam($teamInput: AddTeamInput!) {
		addTeam(addTeamInput: $teamInput) {
			id
			color
			name
		}
	}
`;

// const updateTeamToolsMutation = gql`
// 	mutation updateTeamToolsStatus($UpdateToolInput: [UpdateToolInput!]!) {
// 		updateTeamToolsStatus(tools: $UpdateToolInput) {
// 			id
// 			sharable
// 			tool {
// 				name
// 				id
// 				description
// 				shortDescription
// 				icon
// 			}
// 		}
// 	}
// `;

// const sendTeamToolsLinkMutation = gql`
// 	mutation sendTeamToolsLink($email: String!) {
// 		sendTeamToolsLink(email: $email)
// 	}
// `;

// const updateTeamToolMutation = gql`
// 	mutation updateTeamTool($tool: UpdateToolInput!) {
// 		updateTeamTool(tool: $tool) {
// 			id
// 			sharable
// 			createdDate
// 			businessUsecase
// 			isConnected
// 			lastSynced
// 			isSynced
// 			member
// 			users {
// 				totalCount
// 			}
// 			tool {
// 				id
// 				icon
// 				name
// 				description
// 				shortDescription
// 				documentationLinks {
// 					id
// 					name
// 					link
// 				}
// 				bookLinks {
// 					id
// 					name
// 					link
// 				}
// 				videoLinks {
// 					name
// 					link
// 				}
// 				businessOwner
// 				toolLink
// 				statusLink
// 			}
// 			businessOwner {
// 				id
// 				name
// 				email
// 			}
// 		}
// 	}
// `;

const resendInviteTeammateEmailMutation = gql`
	mutation resendInviteTeammateEmail($email: String!) {
		resendInviteTeammateEmail(email: $email)
	}
`;

const removeEmployeeMutation = gql`
	mutation removeEmployee($id: String!) {
		removeEmployee(employeeId: $id) {
			email
			name
		}
	}
`;

const removeEmployeeFromAllMutation = gql`
	mutation removeEmployee($employeeId: String!) {
		removeEmployee(employeeId: $employeeId)
	}
`;

// const updateTeamToolPropertiesMutation = gql`
// 	mutation updateTeamToolProperties($updateToolPropsInput: UpdateToolPropsInput!, $id: String!) {
// 		updateTeamToolProperties(updateToolPropsInput: $updateToolPropsInput, id: $id) {
// 			id
// 			videoLinks {
// 				id
// 				name
// 				link
// 			}
// 			documentationLinks {
// 				id
// 				name
// 				link
// 			}
// 			bookLinks {
// 				id
// 				name
// 				link
// 			}
// 		}
// 	}
// `;

// const assignToolsToEmployeeMutation = gql`
// 	mutation assignToolsToEmployee($toolIds: [String!]!, $employeeId: String!) {
// 		assignToolsToEmployee(toolIds: $toolIds, employeeId: $employeeId) {
// 			id
// 			user {
// 				id
// 				name
// 			}
// 		}
// 	}
// `;

const updateTeamUsertMutation = gql`
	mutation updateTeamUser($updateTeamUserInput: UpdateTeamUserInput!) {
		updateTeamUser(updateTeamUserInput: $updateTeamUserInput) {
			id
			key
			# reportTo {
			# 	id
			# 	name
			# }
		}
	}
`;

const updateTeamUserArrayMutation = gql`
	mutation updateTeamUserArray($updateTeamUserInputArray: [UpdateTeamUserInput!]!) {
		updateTeamUserArray(updateTeamUserInputArray: $updateTeamUserInputArray) {
			id
			key
			reportTo {
				id
			}
		}
	}
`;

const removeWaitlistMutation = gql`
	mutation removeWaitlist($email: String!, $id: String!) {
		removeWaitlist(email: $email, id: $id)
	}
`;

const removeTeamMutation = gql`
	mutation removeTeam($teamId: String!) {
		removeTeam(teamId: $teamId) {
			status
			message
		}
	}
`;

const confirmEmailMutation = gql`
	mutation confirmEmail($confirmationCode: String!, $mode: String) {
		confirmEmail(confirmationCode: $confirmationCode, mode: $mode) {
			accessToken
			user {
				name
				id
				email
				createdDate
				isConfirmed
				lastLogin
				profilePhoto
				title
				phoneNo
				location
				time
				zone
				teams {
					id
					userTeamRole {
						name
					}
					team {
						name
						logos
						slug
						id
					}
				}
				defaultTeamRole {
					name
				}
				defaultTeam {
					id
					name
					slug
					logos
					shareCode
					createdDate
					users {
						totalCount
						list {
							id
							firstLogin
							accessKeyID
							userTeamRole {
								name
							}
							user {
								id
								name
								email
								isConfirmed
								profilePhoto
								createdDate
								title
							}
						}
					}
					units {
						totalCount
					}
				}
			}
		}
	}
`;

const createBlogMutation = gql`
	mutation createBlog($createBlogInput: CreateBlogInput!) {
		createBlog(createBlogInput: $createBlogInput) {
			tag
			title
			description
			content
			author
			slug
			image
			photo
			dateAdded
		}
	}
`;

const updateBlogMutation = gql`
	mutation updateBlog($blogId: String!, $updateBlogInput: UpdateBlogInput!) {
		updateBlog(blogId: $blogId, updateBlogInput: $updateBlogInput) {
			content
			description
			image
			tag
			title
			dateUpdated
			author
			slug
			photo
			dateAdded
		}
	}
`;

const deleteBlogMutation = gql`
	mutation deleteBlog($blogId: String!) {
		deleteBlog(blogId: $blogId) {
			status
			message
		}
	}
`;

export {
	createBlogMutation,
	updateBlogMutation,
	deleteBlogMutation,
	updateTeamUserArrayMutation,
	updateTeamUsertMutation,
	// assignToolsToEmployeeMutation,
	removeEmployeeFromAllMutation,
	// updateTeamToolPropertiesMutation,
	// updateTeamToolMutation,
	changePassword,
	forgotPasswordMutation,
	resetPasswordWithVerificationCodeMutation,
	updateUserProfileMutation,
	// addToolToTeamMutation,
	// removeToolFromTeamMutation,
	addTeamMutation,
	// updateTeamToolsMutation,
	// sendTeamToolsLinkMutation,
	resendInviteTeammateEmailMutation,
	removeEmployeeMutation,
	removeWaitlistMutation,
	removeTeamMutation,
	confirmEmailMutation,
};
