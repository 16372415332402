<template>
	<div class="overview w-screen overflow-x-hidden">
		<div class="lg:px-12 bg-dark-color pb-20">
			<header class="py-10 md:py-20 px-0 md:px-5">
				<div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-28 flex justify-between items-center">
					<div class="w-full">
						<h1 class="md:text-5xl text-4xl leading-10 mb-2 font-bold text-white">
							<span class="text-ob-light-purple-color">Hi</span>
							{{ user ? user.name : "" }},
						</h1>
						<p class="text-white md:text-xl mb-6 md:mb-0 text-base">
							Here's
							<span class="text-ob-light-purple-color">Analytics</span> overview for you.
						</p>
					</div>
				</div>
			</header>
		</div>
		<main class="-mt-20">
			<div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
				<div class="bg-white border border-gray-100 rounded-lg">
					<div class="flex flex-col">
						<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
								<div class="overflow-hidden sm:rounded-lg">
									<div
										class="bg-white px-4 py-5 border-b border-gray-100 sm:px-6 flex justify-between"
									>
										<div>
											<select
												v-model="preInt"
												class="block w-full rounded-md border border-gray-100 py-2 pl-3 pr-10 text-base focus:border-gray-100 focus:outline-none sm:text-xs"
											>
												<option value="2419200">Monthly</option>
												<option value="7257600">Quarterly</option>
												<option value="14515200">BiAnually</option>
												<option value="29030400">Yearly</option>
											</select>
										</div>

										<div class="">
											<span class="inline-flex rounded-md">
												<router-link
													to="/analytics"
													class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-ob-purple-color hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700"
													>View Secret Pull Data</router-link
												>
											</span>
										</div>
									</div>

									<div v-if="retentionLoading && !retentionError">
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
									</div>
									<error v-else-if="!retentionLoading && retentionError" />

									<empty
										v-else-if="retention?.totalCount === 0"
										:message="{ text: `There's nothing here, yet` }"
									/>

									<table v-else class="min-w-full divide-y divide-gray-100" id="table">
										<thead>
											<tr>
												<th
													class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
												>
													Timeline
												</th>

												<th
													class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
												>
													Value
												</th>
											</tr>
										</thead>

										<tbody
											class="bg-white divide-y divide-gray-200"
											v-if="!retentionLoading && !retentionError && !retention?.length"
										>
											<tr v-for="ret in retention.data" :key="ret.id">
												<td class="px-6 py-4 whitespace-no-wrap">
													<div class="flex items-center">
														<div class="ml-4">
															<div class="text-sm leading-5 font-medium text-gray-900">
																{{ formatDate(splitString(ret.key)[0]) }} -
																{{ formatDate(splitString(ret.key)[1]) }}
															</div>
														</div>
													</div>
												</td>

												<td class="px-6 py-4 whitespace-no-wrap">
													<div class="flex items-center">
														<div class="ml-4">
															<div class="text-sm leading-5 font-medium text-gray-900">
																{{ roundToTwo(ret.value) }}%
															</div>
														</div>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { computed } from "vue-demi";
import { autoPick } from "@/utils/index";
import { formatDate } from "@/utils/date";
import Error from "../../components/Error.vue";
import Empty from "../../components/Empty.vue";
import { useQuery } from "@vue/apollo-composable";
import { getUsersGrowthAnalyticsDataQuery } from "../../graphql/query";
import ToolSkeleton from "../../components/skeleton/ToolSkeleton.vue";
import { roundToTwo, splitString } from "../../utils";

export default {
	name: "user retention",
	components: {
		//  PageNav,
		ToolSkeleton,
		Error,
		Empty,
	},

	setup() {
		const store = useStore();
		const nextPage = ref(0);
		const user = computed(() => store.state.user);

		const timeDurations = {
			Monthly: 2419200,
			Quarter: 7257600,
			BiAnually: 14515200,
			Yearly: 29030400,
		};

		const preInt = ref(timeDurations.Monthly);

		const interval = computed(() => Number(preInt.value));

		const {
			result: retentionResult,
			loading: retentionLoading,
			error: retentionError,
		} = useQuery(getUsersGrowthAnalyticsDataQuery, {
			interval,
		});

		const retention = computed(() => autoPick(retentionResult.value));

		return {
			user,
			preInt,
			nextPage,
			retention,
			roundToTwo,
			formatDate,
			splitString,
			timeDurations,
			retentionError,
			retentionLoading,
		};
	},
};
</script>

<style scoped>
.dropdown:hover .dropdown-menu {
	display: block;
}
</style>
