import DashboardLayout from "@/layouts/Dashboard";
// import store from "../../store/index";
// // import { computed } from "vue";

// const role = computed(() => store.state.role)
export default [
	// Auth
	{
		path: "/login",
		name: "login",
		component: () => import("@/views/protected/auth/Login.vue"),
	},
	{
		path: "/confirm-email/:id/login",
		name: "confirmemailpagelogin",
		component: () => import("@/views/protected/auth/NewConfirmEmail.vue"),
	},
	{
		path: "/await-confirm",
		name: "awaitconfirm",
		component: () => import("@/views/protected/auth/AwaitConfirmation.vue"),
	},
	// {
	// 	path: "/forgotpassword",
	// 	name: "forgotpassword",
	// 	component: () => import("@/views/protected/auth/Forgotpassword.vue")
	// },
	{
		path: "/error",
		name: "errorpage",
		component: () => import("@/views/protected/Error.vue"),
	},
	// {
	// 	path: "/recover-email/:vcode",
	// 	name: "recoverpasswithcode",
	// 	component: () => import("@/views/protected/auth/RecoverPassWithCode.vue")
	// },

	// App
	{
		path: "/",
		redirect: `/overview`,
		component: DashboardLayout,
		children: [
			{
				path: `/overview`,
				name: "home",
				component: () => import("@/views/protected/Home.vue"),
			},

			// Teams
			{
				path: `/teams`,
				name: "teams",
				component: () => import("@/views/protected/Teams.vue"),
			},

			// USers
			{
				path: `/users`,
				name: "users",
				component: () => import("@/views/protected/Users.vue"),
			},
			// Blogs
			// {
			// 	path: `/blog`,
			// 	name: "blog",
			// 	component: () => import("@/views/protected/Blog.vue"),
			// },
			// {
			// 	path: `/blog/create`,
			// 	name: "create",
			// 	component: () => import("@/views/protected/blog/Create.vue"),
			// },
			// {
			// 	path: `/blog/:blogSlug/edit`,
			// 	name: "edit",
			// 	component: () => import("@/views/protected/blog/Edit.vue"),
			// },
			{
				path: `/subscribers`,
				name: "subscribers",
				component: () => import("@/views/protected/SubcribedEmails.vue"),
			},
			// {
			// 	path: `/demos`,
			// 	name: "demos",
			// 	component: () => import("@/views/protected/Demo.vue"),
			// },
			{
				path: `/analytics`,
				name: "analytics",
				component: () => import("../protected/Analytics.vue"),
				// children: [
				// 	{
				// 		path: "/analytics/:list",
				// 		name: "analyticsListItem",
				// 		component: () => import("@/components/Analytics/analyticsListItem.vue"),
				// 	},
				// ],
			},
			{
				path: "/analytics/:analyticId",
				name: "analyticsListItem",
				component: () => import("@/components/Analytics/userList.vue"),
			},
			{
				path: "/analytics/team/:analyticId",
				name: "analyticsTeamList",
				component: () => import("@/components/Analytics/teamList.vue"),
			},

			{
				path: "/analytics/userRetention",
				name: "userRetention",
				component: () => import("@/components/Analytics/userRetention.vue"),
			},
			{
				path: "/billings",
				name: "billings",
				component: () => import("../protected/Billings.vue"),
			},

			// Waitlist
			// {
			// 	path: `/waitlist`,
			// 	name: "waitlist",
			// 	component: () => import("@/views/protected/Waitlist.vue")
			// },

			// Employees
			// {
			// 	path: "/team/:slug/employees",
			// 	name: "employees",
			// 	component: () => import("@/views/protected/Employees.vue")
			// },

			// Tools
			// {
			// 	path: "/tools",
			// 	name: "tools",
			// 	component: () => import("@/views/protected/Tools.vue")
			// },

			// Account
			// {
			// 	path: "/account",
			// 	name: "profile",
			// 	component: () => import("@/views/protected/account/Profile.vue")
			// },
			// {
			// 	path: "/account/change_password",
			// 	name: "changepassword",
			// 	component: () => import("@/views/protected/account/ChangePassword.vue")
			// },

			// Settings
			// {
			// 	path: "/settings",
			// 	name: "general",
			// 	component: () => import("@/views/protected/settings/General.vue")
			// }
		],
	},
];
