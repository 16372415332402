<template>
	<nav
		class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-100 sm:px-6"
	>
		<div class="hidden sm:block">
			<p class="text-sm leading-5 text-gray-700">
				Showing
				<span class="font-medium">{{ nextPage + 1 }}</span>
				to
				<span class="font-medium">{{
					nextPage === totalCount - (totalCount % perPage) ? totalCount : nextPage + perPage
				}}</span>
				of
				<span class="font-medium">{{ totalCount }}</span>
				results
			</p>
		</div>
		<div class="flex-1 flex justify-between sm:justify-end">
			<span
				:class="nextPage <= 0 && 'pointer-events-none opacity-50'"
				@click="prev"
				class="cursor-pointer relative inline-flex items-center px-4 py-2 border border-gray-100 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
			>
				Previous
			</span>
			<span
				@click="next"
				:class="
					nextPage + (totalCount % perPage) >= totalCount || totalCount <= perPage
						? 'opacity-50 pointer-events-none'
						: ''
				"
				class="cursor-pointer ml-3 relative inline-flex items-center px-4 py-2 border border-gray-100 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
			>
				Next
			</span>
		</div>
	</nav>
</template>

<script>
export default {
	name: "PageNav",
	props: {
		totalCount: Number,
		nextPage: Number,
		prev: Function,
		next: Function,
		perPage: Number,
	},
	// setup(props){
	//   console.log(props.totalCount)
	//   console.log(props.nextPage)
	//   console.log(props.prev)
	//   console.log(props.next)
	//   console.log(props.perPage)
	// }
};
</script>

<style></style>
