<template>
	<div class="overview w-screen overflow-x-hidden">
		<div class="lg:px-12 bg-dark-color pb-20">
			<header class="py-10 md:py-20 px-0 md:px-5">
				<div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-28 flex justify-between items-center">
					<div class="w-full">
						<h1 class="md:text-5xl text-4xl leading-10 mb-2 font-bold text-white">
							<span class="text-ob-light-purple-color">Hi</span>
							{{ user ? user.name : "" }},
						</h1>
						<p class="text-white md:text-xl mb-6 md:mb-0 text-base">
							Here's
							<span class="text-ob-light-purple-color">Orgs.</span> breakdown for you.
						</p>
					</div>
					<div class="flex py-4 px-5 md:py-0 md:px-0 rounded leading-8">
						<div class="flex flex-col md:mx-10">
							<span class="text-gray-400 uppercase text-xs font-semibold">Orgs.</span>
							<span class="text-ob-yellow-color font-bold text-3xl md:text-5xl stat">
								{{ teams?.totalCount }}
								<!-- 266 -->
							</span>
						</div>
					</div>
				</div>
			</header>
		</div>

		<!-- Activity list  -->
		<main class="-mt-20">
			<div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
				<div class="bg-white border border-gray-100 rounded-lg overflow-hidden">
					<div class="flex flex-col">
						<div class="-my-2 sm:-mx-6 lg:-mx-8 overflow-x-auto">
							<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
								<div class="">
									<div
										class="bg-white px-4 py-5 border-b border-gray-100 sm:px-6 flex justify-between"
									>
										<div>
											<h3 class="text-lg leading-6 font-medium text-gray-900">Organizations.</h3>
											<p class="mt-1 text-sm leading-5 text-gray-500">
												List of Organizations on Onboardbase
											</p>
										</div>

										<button class="border-none focus:outline-none" @click="downloadAllData">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												class="w-4 h-4 mr-2 text-gray-400"
												viewBox="0 0 20 20"
												fill="currentColor"
											>
												<path
													fill-rule="evenodd"
													d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
													clip-rule="evenodd"
												/>
											</svg>
										</button>
									</div>

									<div v-if="teamsLoading && !teamsError">
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
									</div>
									<error v-else-if="!teamsLoading && teamsError" />
									<empty
										v-else-if="teams?.teams && teams?.teams?.length === 0"
										:message="{ text: `There's nothing here, yet.` }"
									/>
									<table
										v-else
										class="min-w-full divide-y divide-gray-100 overflow-x-auto"
										id="table"
									>
										<thead>
											<tr>
												<th
													class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
												>
													Email
												</th>
												<!-- <th
													class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
												>
													Name
												</th> -->
												<th
													class="px-2 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
												>
													<span class="mr-2 inline-flex items-center">Teammates</span>
												</th>
												<th
													class="px-2 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
												>
													<span class="mr-2 inline-flex items-center">Projects</span>
												</th>
												<th
													class="px-2 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
												>
													<span class="mr-2 inline-flex items-center">Join date</span>
												</th>
												<th
													class="px-3 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
												>
													Owner's Last login
												</th>
												<th
													class="px-2 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
												>
													Secret Pull
												</th>
												<th
													class="px-3 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
												>
													Hear About Us
												</th>
											</tr>
										</thead>
										<tbody
											v-if="!teamsLoading && !teamsError && teams?.teams?.length"
											class="bg-white divide-y divide-gray-100"
										>
											<!-- search -->
											<!-- <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
												<div
													class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-no-wrap"
												>
													<div class="ml-4 mt-4 flex-1 flex">
														<div class="max-w-lg w-full lg:max-w-lg">
															<label for="search" class="sr-only">Search</label>
															<div class="relative">
																<div
																	class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
																>
																	<svg
																		class="h-5 w-5 text-gray-400"
																		fill="currentColor"
																		viewBox="0 0 20 20"
																	>
																		<path
																			fill-rule="evenodd"
																			d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
																			clip-rule="evenodd"
																		></path>
																	</svg>
																</div>
																<input
																	id="search"
																	class="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:border-blue-300 focus:shadow-outline-blue sm:text-sm transition duration-150 ease-in-out"
																	placeholder="Search"
																	type="search"
																/>
															</div>
														</div>
													</div>
												</div>
											</div> -->

											<!-- Teams -->
											<tr v-for="team in teams.teams" :key="team.id">
												<td class="px-6 py-4 whitespace-no-wrap">
													<div class="flex items-center">
														<div
															v-if="team.logos"
															class="flex-shrink-0 h-10 w-10 overflow-hidden rounded-md"
														>
															<img
																class="h-10 w-10 rounded-full object-contain object-center"
																:src="team.logos"
																alt=""
															/>
														</div>
														<div
															v-else
															class="flex-shrink-0 h-10 w-10 uppercase bg-ob-purple-color text-white overflow-hidden rounded-md font-bold flex justify-center items-center"
														>
															{{ team.name[0] }}
														</div>
														<div class="ml-4">
															<div class="text-sm leading-5 font-medium text-gray-900">
																{{ team.name }}
															</div>
															<div class="text-sm leading-5 text-gray-500">
																{{ getOwner(team.users.list)?.owner }}
															</div>
														</div>
													</div>
												</td>
												<!-- <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
													{{ getOwner(team.users.list)?.owner }}
												</td> -->
												<td class="px-3 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
													{{ team.users.totalCount }}
												</td>
												<td class="px-3 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
													{{
														Number(getSecretCount(team.noOfTeamProjectsCount)["AK"]) +
														Number(getSecretCount(team.noOfTeamProjectsCount)["PW"])
													}}
													<span
														class="bg-indigo-50 text-ob-purple-color rounded-lg p-2 py-1 text-xs font-medium"
													>
														{{
															`${getSecretCount(team.noOfTeamProjectsCount)["AK"]} ENV, ${
																getSecretCount(team.noOfTeamProjectsCount)["PW"]
															} LC`
														}}
													</span>
												</td>
												<td class="px-3 py-4 whitespace-no-wrap text-sm leading-5 font-medium">
													{{ team.createdDate?.slice(0, 10) }}
												</td>
												<td class="px-3 py-4 whitespace-no-wrap text-sm leading-5 font-medium">
													{{ getOwner(team.users.list)?.lastLogin?.slice(0, 10) }}
												</td>
												<td class="px-3 py-4 whitespace-no-wrap text-sm leading-5 font-medium">
													{{ team?.totalTeamCount || "N/A" }}
												</td>
												<td class="px-3 py-4 whitespace-no-wrap text-sm leading-5 font-medium">
													{{ team?.hearAboutUs || "N/A" }}
												</td>
												<td class="px-3 py-4 whitespace-no-wrap text-sm leading-5 font-medium">
													<!-- :class="removeTeamLoading && 'pointer-events-none opacity-25'" -->
													<span
														@click="showDeleteTeamModal(team.id, team.name)"
														class="cursor-pointer ml-3 relative inline-flex items-center px-4 py-2 text-sm leading-5 font-medium rounded-md text-gray-400 hover:text-red-500 focus:outline-none transition ease-in-out duration-150"
													>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															class="h-5 w-5"
															viewBox="0 0 20 20"
															fill="currentColor"
														>
															<path
																fill-rule="evenodd"
																d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
																clip-rule="evenodd"
															/>
														</svg>
													</span>
												</td>
											</tr>
										</tbody>
									</table>

									<!-- Pagination -->
									<page-nav
										:class="teamsLoading && 'pointer-events-none'"
										:totalCount="teams?.totalCount"
										:nextPage="nextPage"
										:prev="prev"
										:next="next"
										:perPage="10"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { dif_btw_dates } from "@/utils/date.js";
import { teamsQuery } from "../../graphql/query";
import { useQuery, useResult } from "@vue/apollo-composable";
import PageNav from "@/components/pagination/PageNav";
import scrollToTop from "@/utils/scrollToTop";
import Error from "../../components/Error.vue";
import ToolSkeleton from "../../components/skeleton/ToolSkeleton.vue";
import Empty from "../../components/Empty.vue";

export default {
	name: "Home",
	components: {
		PageNav,
		Error,
		ToolSkeleton,
		Empty,
	},
	props: {
		team: Object,
	},

	setup() {
		const store = useStore();
		const user = computed(() => store.state.user);
		const currentTeam = computed(() => store.state.currentTeam);
		const showNew = (d) => dif_btw_dates(d);
		const nextPage = ref(0);
		const pattern = ref("");
		const showDeleteTeamModal = (id, name) => {
			store.commit("setDeleteTeamModal", {
				state: true,
				forTeam: true,
				name,
				id,
				refetch,
			});
		};

		const {
			// onResult: onTeamsResult,
			result: teamsResult,
			loading: teamsLoading,
			variables,
			error: teamsError,
			refetch,
		} = useQuery(teamsQuery, {
			take: 10,
			skip: 0,
		});

		// const { mutate: removeTeam, loading: removeTeamLoading } = useMutation(
		// 	removeTeamMutation
		// );
		const getOwner = (arr) => {
			const user = arr.filter(
				(each) => each.userTeamRole && each.userTeamRole.name.toLowerCase() === "owner"
			);
			if (user[0]) {
				return {
					owner: user[0].user.email,
					lastLogin: user[0].user.lastLogin,
				};
			}
			return null;
		};

		const teams = useResult(teamsResult);

		// onTeamsResult(data => {
		// 	if (data.data) {
		// 		teams.value = data.data.getTeams;
		// 		// teamsCount.value = teams.value.length;
		// 	}
		// });

		const updateVariable = () =>
			(variables.value = {
				id: user.value.id,
				skip: nextPage.value,
				name: pattern.value,
			});

		const next = () => {
			const limit = teams.value.totalCount - (teams.value.totalCount % 10);
			if (nextPage.value !== limit) {
				nextPage.value = nextPage.value + 10;
				updateVariable();
				scrollToTop();
			}
		};

		const prev = () => {
			if (nextPage.value > 0) {
				nextPage.value = nextPage.value - 10;
				updateVariable();
				scrollToTop();
			}
		};

		const search = () => {
			if (pattern.value.length === 0 || pattern.value.length >= 2) {
				nextPage.value = 0;
				updateVariable();
			}
		};

		// const activateDel = (teamId) => {
		// 	removeTeam({ teamId })
		// 		.then(() => {
		// 			refetch();
		// 			handleGlobalAlert(true, "success", "Team removed successfully");
		// 		})
		// 		.catch(() => handleGlobalAlert(true, "failed", "Team was not removed. Try again"));
		// };

		const getSecretCount = (noOfTeamProjectsCount) => {
			let initialState = {
				AK: "0",
				// N: "0",
				// CC: "0",
				PW: "0",
				// FL: "0"
			};
			if (noOfTeamProjectsCount.length) {
				for (const i of noOfTeamProjectsCount) {
					switch (i.type) {
						case "api_keys":
							initialState["AK"] = i.count;
							break;
						case "auth":
							initialState["PW"] = i.count;
							break;
						// case 'credit_card':
						// 	initialState["CC"] = i.count;
						// 	break;
						// case 'document':
						// 	initialState["N"] = i.count;
						// 	break;
						// case 'file':
						// 	initialState["FL"] = i.count;
						// 	break;
						// default:
						// 	break;
					}
				}
			}
			return initialState;
		};

		function exportToCsv(table, separator = ",") {
			// Select rows from table_id
			var rows = document.querySelectorAll("#" + table + " tr");
			// Construct csv
			var csv = [];
			for (var i = 0; i < rows.length; i++) {
				var row = [],
					cols = rows[i].querySelectorAll("td, th");
				for (var j = 0; j < cols.length; j++) {
					const seperatedDiv = cols[j].innerText.split(/\r?\n/); // fseperate joined columns
					var data, data2;
					if (seperatedDiv.length > 1) {
						data = seperatedDiv[2] || seperatedDiv[1]; // return []
						data2 = seperatedDiv[1].replace(/"/g, '""');
					} else {
						// Clean innertext to remove multiple spaces and jumpline (break csv)
						data2 = undefined;
						data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, "").replace(/(\s\s)/gm, " ");
					}

					data = data.replace(/"/g, '""');
					// Push escaped string
					row.push('"' + data + '"');
					if ((i === 0 && data.toLowerCase().trim() === "email") || (data2 && j === 0)) {
						row.push('"' + (data2 || "NAME") + '"');
					}
				}
				csv.push(row.join(separator));
			}
			var csv_string = csv.join("\n");
			// Download it
			var filename = "OBB_org_table" + "_" + new Date().toLocaleDateString() + ".csv";
			var link = document.createElement("a");
			link.style.display = "none";
			link.setAttribute("target", "_blank");
			link.setAttribute("href", "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string));
			link.setAttribute("download", filename);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}

		async function downloadAllData() {
			await refetch({ skip: 0, take: teams.value.totalCount });

			exportToCsv("table");
		}

		return {
			downloadAllData,
			exportToCsv,
			showNew,
			user,
			currentTeam,
			teamsLoading,
			teamsError,
			teams,
			// activateDel,
			nextPage,
			pattern,
			search,
			next,
			prev,
			getOwner,
			// removeTeamLoading,
			showDeleteTeamModal,
			getSecretCount,
			// teamsCount
		};
	},
};
</script>

<style scoped></style>
