<template>
	<div v-if="showError" class="relative overflow-hidden">
		<img
			class="home-middleimage left-0 right-0 w-full absolute"
			src="@/assets/img/middle-image.svg"
			alt="Middle"
		/>
		<div class="relative">
			<div class="error h-screen flex flex-col justify-center content-center">
				<div class="px-4 sm:mx-auto sm:w-full sm:max-w-lg">
					<router-link to="/">
						<img
							class="mx-auto h-8 md:h-10 w-auto"
							src="@/assets/img/new-logo-dark.png"
							alt="Onboardbase logo"
						/>
					</router-link>
					<h2 class="mt-6 text-center text-xl md:text-3xl leading-9 font-extrabold">
						Authentication Failed!
					</h2>
					<p class="mt-2 text-center text-sm leading-5 text-gray-500">
						Please close this window and try authenticating again.
					</p>
				</div>
				<div class="mt-5 sm:mx-auto sm:w-full sm:max-w-md">
					<div class="bg-white py-3 px-4 shadow-xs rounded-lg">
						<div class="text-center text-gray-500 text-sm normal-case">
							<p>It looks like you may have clicked on an invalid email verification link.</p>
						</div>
					</div>
				</div>
				<div class="mt-6 w-52 mx-auto">
					<router-link to="/">
						<span
							class="rounded block w-full px-5 py-2.5 text-center font-medium text-white bg-ob-purple-color hover:bg-indigo-500 focus:outline-none focus:border-indigo-500 focus:shadow-outline-indigo active:bg-indigo-500 transition duration-150 ease-in-out"
							>Home</span
						>
					</router-link>
				</div>
			</div>
		</div>
	</div>
	<!-- <new-team-modal /> -->
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { confirmEmailMutation } from "@/graphql/mutation";
import { useMutation } from "@vue/apollo-composable";
import { handleGlobalAlert } from "@/components/connectButtons/shared";
import { onMounted, reactive, toRefs } from "vue";
// import { decryptData, encryptData } from "@/utils/encrypt";
// import { getUsersToken } from "@/utils";
// import NewTeamModal from "../../../components/modals/NewTeamModal.vue";
import { useStore } from "vuex";

export default {
	// components: { NewTeamModal },
	name: "NewConfirmEmailPage",

	setup() {
		const route = useRoute();
		const router = useRouter();
		const store = useStore();

		const state = reactive({
			confirmationCode: route.params.id,
			showError: false,
			errorMessage: null,
		});

		const {
			mutate: confirmEmail,
			// loading: confirmEmiailLoading,
		} = useMutation(confirmEmailMutation);

		// const showCreateTeamModal = teams => {
		// 	store.commit("setCreateTeamModal", { state: true, teams });
		// };

		onMounted(() => {
			store.commit("setLoading", true);
			confirmEmail({
				confirmationCode: state.confirmationCode,
				mode: route.name === "confirmemailpagelogin" ? "login" : null,
			})
				.then((queryResult) => {
					handleGlobalAlert(true, "success", "Email Confirmed!");
					// handleLogin(queryResult.data.confirmEmail);
					const accessToken = queryResult.data.confirmEmail.accessToken;
					const user = queryResult.data.confirmEmail.user;
					if (accessToken) {
						localStorage.setItem("o-token-ap", accessToken);
						store.commit("setToken", accessToken);
					}
					user && store.commit("setUser", user);

					if (user.isConfirmed === true) {
						router.push({ path: "/" });
						handleGlobalAlert(true, "success", `Welcome to admin panel`);
					} else {
						handleGlobalAlert(true, "success", `Confirm your email`);
						router.push({ path: "/await-confirm" });
					}
				})
				.catch((error) => {
					store.commit("setLoading", false);
					state.showError = true;
					state.errorMessage = error.message;
					handleGlobalAlert(true, "failed", error.message);
				});
		});

		return {
			...toRefs(state),
			// confirmEmiailLoading,
		};
	},
};
</script>

<style></style>
