<template>
	<div class="bg-white border border-gray-100 rounded-lg">
		<div class="flex flex-col">
			<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div class="align-middle inline-block min-w-full w-full">
					<div class="overflow-auto sm:rounded-lg">
						<div class="bg-white justify-between flex px-4 py-5 border-b border-gray-100 sm:px-6">
							<div class="">
								<h3 class="text-lg leading-6 font-medium text-gray-900">Organizations' plan</h3>
								<p class="mt-1 text-sm leading-5 text-gray-500">List of organizations and plan</p>
							</div>
							<div class="flex">
								<div class="w-full mr-4">
									<label for="search" class="sr-only">Search Organization name...</label>
									<div class="relative">
										<div
											class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
										>
											<svg class="w-4 h-4 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
												<path
													fill-rule="evenodd"
													d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
													clip-rule="evenodd"
												></path>
											</svg>
										</div>
										<input
											id="search"
											class="block w-full py-2 pl-10 pr-3 leading-5 placeholder-gray-500 transition duration-150 ease-in-out border border-gray-100 rounded-md focus:outline-none focus:placeholder-gray-400 focus:border-blue-300 focus:shadow-outline-blue sm:text-sm"
											placeholder="Search Organization name ..."
											type="search"
											v-model="name"
										/>
									</div>
								</div>
								<div class="mr-2">
									<select
										name="roles"
										id="roles"
										class="block py-2 pl-3 pr-10 leading-6 border-gray-100 cursor-pointer form-select focus:outline-none focus:shadow-outline-blue sm:text-sm sm:leading-5"
										v-model="paymentTier"
										@change="
											() => {
												skip = 0;
												take = 10;
											}
										"
									>
										<option selected :value="undefined">All plans</option>
										<option value="ENTERPRISE">ENTERPRISE</option>
										<option value="BUSINESS">BUSINESS</option>
										<option value="STARTUP">STARTUP</option>
										<option value="FREE">FREE</option>
									</select>
								</div>
							</div>
						</div>

						<error v-if="error && !loading" />
						<empty
							v-else-if="teams?.list?.length == 0"
							:message="{ text: `Organization does not exist` }"
						/>

						<table
							aria-describedby="mydesc"
							v-else-if="!error"
							class="min-w-full divide-y divide-gray-100"
						>
							<thead class="w-full">
								<tr>
									<th
										class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
									>
										Organization
									</th>
									<th
										class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
									>
										Price
									</th>
									<th
										class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
									>
										Secret Limit & Usage
									</th>

									<th
										class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
									>
										Current Plan
									</th>

									<th
										class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
									>
										Renewal Date
									</th>
									<th
										class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
									>
										Enterprise details
									</th>
								</tr>
							</thead>

							<tbody v-if="!loading" class="bg-white divide-y divide-gray-100">
								<tr v-for="team in teams.list" :key="team.id">
									<td class="px-6 py-4 whitespace-no-wrap">
										<div class="flex items-center">
											<div
												class="flex-shrink-0 h-10 w-10 uppercase bg-ob-purple-color text-white overflow-hidden rounded-md font-bold flex justify-center items-center"
											>
												{{ team.name[0] }}
											</div>
											<div class="ml-4">
												<div class="text-sm leading-5 capitalize font-medium text-gray-900">
													{{ team.name }}
												</div>
												<div class="text-xs text-gray-400">
													{{ team.addedBy?.email }}
												</div>
											</div>
										</div>
									</td>
									<td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium">
										<component :is="getTeamPrice(team)" />
									</td>
									<td
										class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium flex flex-col justify-center items-center"
									>
										{{ team?.teamPaymentTier?.totalSecretsLimit || "Limit not set" }}
										<span class="text-xs bg-blue-50 text-blue-600 rounded-md p-1 ml-1"
											>{{
												team.teamPaymentTier?.subscriptionSession?.secretProcessed || 0
											}}
											used</span
										>

										<!-- {{ team?.teamPaymentTier?.enterpriseSecretProcessLimit || 0 }} -->
									</td>
									<td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium">
										{{ team.paymentTier?.tier }}
									</td>
									<td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium">
										<div v-if="team.paymentTier?.tier !== 'FREE'">
											<div v-if="team?.teamPaymentTier?.rawRenewalDate">
												{{ formatDate(team?.teamPaymentTier?.rawRenewalDate).split("|")[0] }}
											</div>
											<span
												v-if="
													team?.teamPaymentTier?.status && team?.teamPaymentTier?.isActive === true
												"
												class="text-xs bg-green-50 text-green-600 rounded-md p-1 ml-1"
												>{{ team?.teamPaymentTier?.status }}</span
											>
											<span
												v-else-if="
													team?.teamPaymentTier?.isActive === false &&
													['active', 'trialing'].includes(team?.teamPaymentTier?.status)
												"
												class="text-xs bg-red-50 text-red-600 rounded-md p-1 ml-1"
												>cancled at end</span
											>
										</div>
									</td>
									<td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium">
										<div
											v-if="team.teamPaymentTier?.enterprisePrice"
											class="flex flex-col gap-2 justify-center items-start"
										>
											<span
												class="text-xs bg-blue-50 text-blue-600 rounded-md p-1 ml-1"
												v-if="team.teamPaymentTier?.enterpriseSecretProcessLimit"
												>{{ team.teamPaymentTier?.enterpriseSecretProcessLimit }} Usage</span
											>
											<span
												class="text-xs bg-gray-50 text-blue-600 rounded-md p-1 ml-1"
												v-if="team.teamPaymentTier?.enterprisePrice"
												>${{ team.teamPaymentTier.enterprisePrice }}
												{{ team.teamPaymentTier?.perUser ? "per user" : "" }}
											</span>
										</div>
									</td>

									<td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium">
										<dropdown>
											<template v-slot:items>
												<ul class="divide-y divide-gray-50 overflow-auto max-h-80 bg-white">
													<li
														v-for="option in itemOptions"
														:key="option.name"
														@click="() => option.handleSelect(team)"
														class="focus:outline-none flex py-2.5 px-4 items-center text-left hover:bg-gray-50 text-xs text-gray-700 font-medium cursor-pointer"
														:class="
															((['Enterprise secret usage count'].includes(option.name) &&
																team.paymentTier.tier !== 'ENTERPRISE') ||
																(!team.teamPaymentTier?.enterprisePrice &&
																	['Copy Enterprise payment link'].includes(option.name)) ||
																(!team.teamPaymentTier?.notPaying &&
																	option.name === 'Cancel no-payment plan')) &&
															'hidden'
														"
													>
														<div class="">
															<div
																class="text-xs font-medium leading-5 flex items-center justify-between"
															>
																<span> {{ option.name }}</span>
															</div>
														</div>
													</li>
												</ul>
											</template>
										</dropdown>
									</td>
								</tr>
							</tbody>
						</table>
						<div v-if="loading">
							<list-skeleton />
							<list-skeleton />
							<list-skeleton />
							<list-skeleton />
							<list-skeleton />
						</div>
						<page-nav
							:nextPage="skip"
							:prev="prev"
							:next="next"
							:perPage="10"
							:totalCount="teams.totalCount"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
	<non-payment-modal
		v-if="nonPaymentModal.state"
		:modal="nonPaymentModal"
		@closeModal="
			(refetchData = false) => {
				if (refetchData) refetch();
				nonPaymentModal = { state: false };
			}
		"
	/>
	<plan-modal
		v-if="enterprisePlanModal.state"
		:modal="enterprisePlanModal"
		@closeModal="
			(refetchData = false) => {
				if (refetchData) refetch();
				enterprisePlanModal = { state: false };
			}
		"
	/>
	<update-enterprise-details-modal
		v-if="updateEnterpriseModal.state"
		:modal="updateEnterpriseModal"
		@closeModal="
			(refetchData = false) => {
				if (refetchData) refetch();
				updateEnterpriseModal = { state: false };
			}
		"
	/>
</template>

<script lang="jsx">
import Error from "./Error.vue";
import Empty from "./Empty.vue";
import { autoPick } from "../utils";
import { computed, defineComponent, ref } from "vue";
import copyText from "../utils/copy";
import ListSkeleton from "./skeleton/ListSkeleton.vue";
import { formatDate } from "@/utils/date";
import scrollToTop from "@/utils/scrollToTop";
import PageNav from "./pagination/PageNav.vue";
import { useQuery } from "@vue/apollo-composable";
import Dropdown from "./dropdowns/dropdown.vue";
import { getOrganizationsWithPlanFilterQuery } from "../graphql/billing/query";
import NonPaymentModal from "./modals/NonPaymentModal.vue";
import PlanModal from "./modals/PlanModal.vue";
import updateEnterpriseDetailsModal from "./modals/updateEnterpriseDetailsModal.vue";

export default {
	components: {
		PageNav,
		ListSkeleton,
		Error,
		Empty,
		Dropdown,
		NonPaymentModal,
		PlanModal,
		updateEnterpriseDetailsModal,
	},
	setup() {
		const skip = ref(0);
		const take = ref(10);
		const name = ref(null);
		const paymentTier = ref(undefined);
		const origin = window.location.origin;
		const nonPaymentModal = ref({ state: false, team: null });
		const enterprisePlanModal = ref({ state: false, team: null });
		const updateEnterpriseModal = ref({ state: false, team: null });
		function handleCopyText(team) {
			copyText(`${origin}/team/${team.slug}/overview?orgId=${team.id}&pay-enterprise=true`);
		}

		function cancelNoPayPlan(team) {
			team;
		}
		const itemOptions = [
			{
				name: "Copy Enterprise payment link",
				handleSelect: handleCopyText,
			},
			{
				name: "Create new enterprise plan",
				handleSelect: (team) => (enterprisePlanModal.value = { team, state: true }),
			},
			{
				name: "Enterprise secret usage count",
				handleSelect: (team) => (updateEnterpriseModal.value = { team, state: true }),
			},
			{
				name: "Manage no-payment plan",
				handleSelect: (team) => (nonPaymentModal.value = { team, state: true }),
			},
			{ name: "Cancel no-payment plan", handleSelect: cancelNoPayPlan },
		];

		const getTeamPrice = (team) => {
			return defineComponent({
				name: "price column",
				render: () => {
					if (team.teamPaymentTier?.notPaying) return <div>Gratuite</div>;
					if (team.paymentTier?.tier === "ENTERPRISE") {
						return (
							<div>
								{!!team.teamPaymentTier?.enterprisePrice && "$"}
								{team.teamPaymentTier?.enterprisePrice}
								{team?.teamPaymentTier?.interval && (
									<span class="text-xs bg-orange-50 text-orange-600 rounded-md p-1">
										{team?.teamPaymentTier?.interval}
									</span>
								)}
								{team?.teamPaymentTier?.perUser && (
									<span class="text-xs bg-blue-50 text-blue-600 rounded-md p-1 ml-1">per user</span>
								)}
							</div>
						);
					}
					if (team.paymentTier?.tier === "FREE") {
						return <div>Hobbyist</div>;
					}
					return (
						<div>
							${team.paymentTier?.pricePerUser}
							<span class="text-xs bg-orange-50 text-orange-600 rounded-md p-1">month</span>
							{<span class="text-xs bg-blue-50 text-blue-600 rounded-md p-1 ml-1">per user</span>}
						</div>
					);
				},
			});
		};

		const { result, loading, error, refetch } = useQuery(getOrganizationsWithPlanFilterQuery, {
			teamsWithPlanInput: { skip, take, name, paymentTier },
		});
		async function refetchData() {
			await refetch({ skip, take, name, paymentTier });
		}
		const teams = computed(() => autoPick(result.value));

		const next = () => {
			const limit = teams.value.totalCount - (teams.value.totalCount % 10);
			if (skip.value !== limit) {
				skip.value = skip.value + 10;
				scrollToTop();
			}
		};

		const prev = () => {
			if (skip.value > 0) {
				skip.value = skip.value - 10;
				scrollToTop();
			}
		};

		return {
			refetchData,
			refetch,
			nonPaymentModal,
			enterprisePlanModal,
			updateEnterpriseModal,
			getTeamPrice,
			prev,
			skip,
			next,
			error,
			origin,
			loading,
			copyText,
			formatDate,
			itemOptions,
			teams,
			paymentTier,
			name,
		};
	},
};
</script>

<style></style>
