import { gql } from "@apollo/client";

export const createEnterpriseForTeamMutation = gql`
	mutation createEnterpriseForTeam($createEnterprisePlanInput: CreateEnterprisePlanInput!) {
		createEnterpriseForTeam(createEnterprisePlanInput: $createEnterprisePlanInput) {
			paymentLink
		}
	}
`;

export const updatePaymentTierMutation = gql`
	mutation updatePaymentTier($editPaymentTierInput: EditPaymentTierInput!) {
		updatePaymentTier(editPaymentTierInput: $editPaymentTierInput) {
			id
			tier
			pricePerUser
			secretProcessLimit
		}
	}
`;

export const editEnterpriseSubMutation = gql`
	mutation editEnterpriseSub($editEnterprisePlanInput: EditEnterprisePlanInput!, $teamId: String!) {
		editEnterpriseSub(editEnterprisePlanInput: $editEnterprisePlanInput, teamId: $teamId)
	}
`;

export const addNonPaymentPlanMutation = gql`
	mutation updateOrgToNonPaymentPlan(
		$paymentTier: PaymentTier!
		$teamId: String!
		$secretUsageLimit: Int
		$monthOrYear: Int
		$interval: String
	) {
		updateOrgToNonPaymentPlan(
			paymentTier: $paymentTier
			teamId: $teamId
			secretUsageLimit: $secretUsageLimit
			monthOrYear: $monthOrYear
			interval: $interval
		)
	}
`;
