import { computed } from "vue";
import store from "../../store/index";
import CryptoJS from "crypto-js";

// Current Team Object
const currentTeam = computed(() => store.state.currentTeam);

// set Global alert
function handleGlobalAlert(show, type, text) {
	store.commit("setGlobalAlert", { show, type, text });
}

// Initiate disconnect tool
const showDisconnectToolModal = (toolName) => {
	store.commit("setDisconnectToolModal", {
		state: toolName ? true : false,
		tool: toolName,
	});
};

// Disconnect tool => Callback is the function to disconnect
const handleDisconnect = (callback, toolName, refetch) => {
	callback()
		.then(() => {
			handleGlobalAlert(true, "success", toolName + " disconnected!");
			refetch();
			showDisconnectToolModal();
		})
		.catch((error) => handleGlobalAlert(true, "failed", error.message));
};

const key = () => `${process.env.VUE_APP_TOOL_STATE_KEY}${currentTeam.value?.id}`;

const encryptText = (d) => {
	const enc = CryptoJS.AES.encrypt(JSON.stringify({ d }), key()).toString();
	return enc.replace(/[+]/g, "plusSign");
};

const decryptText = (e) => {
	const b = e.replace(/plusSign/g, "+");
	const dec = CryptoJS.AES.decrypt(b, key()).toString(CryptoJS.enc.Utf8);
	return dec;
};

export { showDisconnectToolModal, handleDisconnect, handleGlobalAlert, encryptText, decryptText };
