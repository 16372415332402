<template>
	<div class="wrapper" v-if="showpage === true || fromLogin">
		<!-- <waitlist-modal /> -->
		<team-modal />
		<navcomponent />
		<coupon-modal v-if="showCouponModal" />
		<update-tier-modal v-if="showUpdateTierModal.state" />

		<div class="pointer-events-none opacity-100 hidden">
			{{ user.isConfirmed }}
		</div>
		<router-view @click="clearDropdowns"></router-view>
	</div>
</template>

<script>
import NavComponent from "../components/Nav";
import { useStore } from "vuex";
import { useQuery, useResult } from "@vue/apollo-composable";
import { userQuery } from "@/graphql/query";
import { computed, onMounted, onUnmounted, ref, watchEffect } from "vue";
import jwt_decode from "jwt-decode";
import { useRouter } from "vue-router";
import { handleGlobalAlert } from "../components/connectButtons/shared";
// import WaitlistModal from "../Archived/WaitlistModal.vue";
import TeamModal from "../components/modals/TeamModal.vue";
import CouponModal from "../components/modals/CouponModal.vue";
import UpdateTierModal from "../components/modals/updateTierModal.vue";

export default {
	name: "DashboardLayout",
	components: {
		navcomponent: NavComponent,
		// WaitlistModal,
		TeamModal,
		CouponModal,
		UpdateTierModal,
	},

	setup() {
		const store = useStore();
		const router = useRouter();
		// const route = useRoute();
		const loginId = ref("");
		const enabledLogin = ref(false);
		const showpage = ref(false);
		const delToolModal = computed(() => store.state.showToolDelModal);
		const revokeModal = computed(() => store.state.revokeModal);
		const fromLogin = computed(() => store.state.fromLogin);
		// const online = computed(() => store.state.online);
		// const currentTeam = computed(() => store.state.currentTeam);
		// const checkConfirmed = check => check === false && router.push({ path: "/confirmemail" });
		const showCouponModal = computed(() => store.state.couponModal.state);
		const showPlanModal = computed(() => store.state.planModal.state);
		const showUpdateTierModal = computed(() => store.state.updateTierModal);
		const showEditEnterpriseModal = computed(() => store.state.editEnterprisePlanModal);

		const user = computed(() => {
			const user = store.state.user;
			// checkConfirmed(user.isConfirmed);
			return user;
		});

		const clearDropdowns = () => {
			store.commit("setDropdown1", false);
			// store.commit("setShowNotification", false);
		};
		onUnmounted(() => store.commit("setServerError", { state: false }));

		onMounted(() => {
			clearDropdowns();
			const decode = jwt_decode(localStorage.getItem("o-token-ap"));
			if (decode && Date.now() / 1000 >= decode.exp) {
				localStorage.removeItem("o-token-ap");
				handleGlobalAlert(true, "failed", "Please login");
				router.push({ path: "/login" });
			}
		});

		const {
			result: loginResult,
			loading: userQueryLoading,
			error: loginError,
		} = useQuery(userQuery, { id: loginId }, () => ({
			enabled: enabledLogin.value,
		}));

		const res = useResult(loginResult, null, (data) => data.user);
		const userWatch = watchEffect(() => {
			if (userQueryLoading.value === false && res.value && !loginError.value) {
				const user = res.value;
				user && store.commit("setUser", user);
				store.commit("setLoading", false);
				showpage.value = true;
				enabledLogin.value = false;
				userWatch();
				if (!user.isConfirmed === true) {
					handleGlobalAlert(true, "success", `Confirm your email`);
					router.push({ path: "/confirmemail" });
				}
			} else if (userQueryLoading.value === false && loginError.value) {
				localStorage.removeItem("o-token-ap-admin");
				store.commit("setLoading", false);
				handleGlobalAlert(true, "failed", "Please login");
				return router.push({ path: `/login` });
			}
		});

		if (fromLogin.value === false) {
			const authToken = localStorage.getItem("o-token-ap");
			if (
				authToken &&
				authToken !== null &&
				authToken !== "null" &&
				authToken !== undefined &&
				authToken !== "undefined" &&
				authToken.trim() !== ""
			) {
				store.commit("setServerError", false);
				const decode = jwt_decode(authToken);
				if (decode && Date.now() / 1000 < decode.exp) {
					loginId.value = decode.sub;
					enabledLogin.value = true;
					store.commit("setLoading", true);
				} else {
					localStorage.removeItem("o-token-ap-admin");
					store.commit("setLoading", false);
					handleGlobalAlert(true, "failed", "Please login");
					return router.push({ path: `/login` });
				}
			}
		}

		return {
			user,
			store,
			showpage,
			fromLogin,
			revokeModal,
			delToolModal,
			showPlanModal,
			clearDropdowns,
			showCouponModal,
			userQueryLoading,
			showUpdateTierModal,
			showEditEnterpriseModal,
		};
	},
};
</script>
