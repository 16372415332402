const formatDate = (
	date
	// sec
) => {
	if (typeof date === "number") {
		date = new Date(date).toISOString();
	}
	let day = "",
		month = "",
		convMonth = "",
		year = "";
	// time = "";

	const months = [
		"Jan",
		"Feb",
		"Mar",
		"April",
		"May",
		"June",
		"July",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];
	const getMonth = (val) => months[Number(val) - 1];

	let split;
	if (date) {
		split = date.split("-");
		day = `${split[2][0]}${split[2][1]}`;
		month = split[1];
		year = split[0];
		// time = split[2].slice(3, sec ? 11 : 8);
		convMonth = getMonth(month);
		return `${convMonth} ${day}, ${year}`;
		// return `${convMonth} ${day}, ${year} | ${time} GMT`;
	}
};

const dif_btw_dates = (joinedDate, maxDay = 30) => {
	const date1 = new Date(Date.parse(joinedDate));
	const date2 = new Date();
	const difference = date2.getTime() - date1.getTime();
	const days = Math.ceil(difference / (1000 * 3600 * 24));

	return maxDay > Number(days);
};

const min_btw_dates = (start, finish) => {
	if (start && finish) {
		const date1 = new Date(Date.parse(start)).getTime();
		const date2 = new Date(Date.parse(finish)).getTime();
		return Math.floor((date2 - date1) / 60000);
	}
};

//Date one and date two should be in the form of vue-calender object to use this function
const time_diff_btw_dates = (date_1, date_2) => {
	const months = {
		Jan: 1,
		Feb: 2,
		Mar: 3,
		Apr: 4,
		May: 5,
		Jun: 6,
		Jul: 7,
		Aug: 8,
		Sep: 9,
		Oct: 10,
		Nov: 11,
		Dec: 12,
	};
	let dateOne = String(date_1);
	let dateTwo = String(date_2);

	const year_diff = Math.abs(parseInt(dateOne.slice(11, 15)) - parseInt(dateTwo.slice(11, 15)));
	if (year_diff > 0) {
		return year_diff + "y";
	}

	const month_diff = Math.abs(
		parseInt(months[dateOne.slice(4, 7)]) - parseInt(months[dateTwo.slice(4, 7)])
	);
	if (month_diff > 0) {
		return month_diff + "m";
	}

	const day_diff = Math.abs(parseInt(dateOne.slice(8, 10)) - parseInt(dateTwo.slice(8, 10)));
	if (day_diff > 0) {
		return day_diff + "d";
	}
	const hour_diff = Math.abs(parseInt(dateOne.slice(16, 18)) - parseInt(dateTwo.slice(16, 18)));
	if (hour_diff > 1) {
		return hour_diff + "h";
	}

	let min_diff = 0;
	const minuteOne = parseInt(dateOne.slice(19, 21));
	const minuteTwo = parseInt(dateTwo.slice(19, 21));
	min_diff = Math.abs(minuteOne - minuteTwo);

	return min_diff + "m";
};

import { clientTz, listTz } from "timezone-select-js";
const getTimezones = () => listTz();
const myTimezone = () => clientTz();

// function fff(name, list) {
// 	var data = list || listTz();
// 	return data.find(function(item) {
// 		return item.included && item.included.includes(name);
// 	});
// }

// const findTimezone = () => {
// };

// findTimezone()
function convertTZ(date, tzString) {
	return new Date(
		(typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
			timeZone: tzString,
		})
	);
}

const tz = (zone, date) => {
	if (zone && date) {
		let d = convertTZ(date, zone).toString().split("(")[0];
		return zone ? d?.slice(0, d.length - 13) : null;
	}
};

const timeSince = (date) => {
	const seconds = Math.floor((new Date() - date) / 1000);

	let interval = seconds / 31536000;
	if (interval > 1) {
		const c = Math.floor(interval);
		return c + ` years${c > 1 ? "s" : ""}`;
	}

	interval = seconds / 2592000;
	if (interval > 1) {
		const c = Math.floor(interval);
		return Math.floor(interval) + ` month${c > 1 ? "s" : ""}`;
	}

	interval = seconds / 86400;
	if (interval > 1) {
		const c = Math.floor(interval);
		return c + ` day${c > 1 ? "s" : ""}`;
	}

	interval = seconds / 3600;
	if (interval > 1) {
		const c = Math.floor(interval);
		return c + ` hour${c > 1 ? "s" : ""}`;
	}

	interval = seconds / 60;
	if (interval > 1) {
		const c = Math.floor(interval);
		return c + ` minutes${c > 1 ? "s" : ""}`;
	}
	return Math.floor(seconds) + " seconds";
};

const getYears = (arr) => {
	arr = JSON.parse(arr);
	const years = [];
	for (const each of arr) {
		const year = each.createdDate.split("-")[0];
		!years.includes(year) && years.push(year);
	}
	return years;
};

const getYearData = (arr, year) => {
	arr = JSON.parse(arr);
	const data = [];
	for (const each of arr) {
		const y = each.createdDate.split("-")[0];
		if (data[data.length - 1] && y !== data[data.length - 1].createdDate.split("-")[0]) {
			break;
		}
		if (Number(y) == Number(year)) {
			data.push(each);
		}
	}
	return { list: data, totalCount: data.length };
};

const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

const getMonthAndYear = (createdDate) => {
	const d = new Date(Date.parse(createdDate));
	const year = createdDate.split("-")[0];
	return `${months[d.getMonth()]} ${year}`;
};

export {
	getMonthAndYear,
	getYearData,
	getYears,
	timeSince,
	formatDate,
	dif_btw_dates,
	min_btw_dates,
	time_diff_btw_dates,
	getTimezones,
	myTimezone,
	tz,
};
