<template>
	<transition name="fadeIn">
		<div :class="loading && 'pointer-events-none'" class="fixed z-50 inset-0 overflow-y-auto">
			<div
				class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
			>
				<div class="fixed inset-0 transition-opacity" aria-hidden="true">
					<div
						@click.self="closeModal"
						class="absolute inset-0 bg-gray-800 bg-opacity-75 modal-blur"
					></div>
				</div>
				<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"
					>&#8203;</span
				>

				<div
					class="main inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
					role="dialog"
					aria-modal="true"
					aria-labelledby="modal-headline"
				>
					<div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
						<button
							@click="closeModal"
							type="button"
							class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
						>
							<span class="sr-only">Close</span>
							<svg
								class="h-6 w-6"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>

					<div class="sm:flex flex-wrap sm:items-start w-full">
						<div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
							<h3 class="text-lg leading-6 font-medium mb-4" id="modal-headline">
								{{ show.title }}
							</h3>

							<div class="my-4 w-full">
								<label for="amount" class="block text-sm font-medium leading-5 text-gray-700">
									Plan
								</label>
								<div class="mt-1 rounded-md">
									<select
										v-model="selectedPlan"
										@change="resetValues"
										class="form-select block w-full mt-1"
									>
										<option disabled value="">Select a plan</option>
										<option v-for="tier in filteredTiers" :key="tier?.id" :value="tier?.tier">
											{{ tier?.tier }}
										</option>
									</select>
								</div>
							</div>

							<!-- Price -->
							<div class="my-4 w-full">
								<label for="amount" class="block text-sm font-medium leading-5 text-gray-700">
									Price per user
								</label>
								<div class="mt-1 rounded-md">
									<input
										id="price"
										v-model="price"
										:disabled="selectedPlan === 'FREE'"
										placeholder="Price per user"
										class="form-input block w-full border border-gray-200 transition duration-150 ease-in-out sm:text-sm sm:leading-5 cursor-pointer"
									/>
								</div>
							</div>

							<!--LIMIT  -->
							<div class="my-4 w-full">
								<label for="amount" class="block text-sm font-medium leading-5 text-gray-700">
									Secret Process Limit
								</label>
								<div class="mt-1 flex-1 rounded-md">
									<input
										id="limit"
										type="number"
										v-model="limit"
										placeholder="Update Limit"
										class="form-input block w-full border border-gray-200 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
									/>
								</div>
							</div>

							<div class="mt-1 rounded-md flex gap-2 flex-col">
								<label for="upateActiveTeams" class="text-sm font-medium leading-5 text-gray-700">
									Update active Organizations immediately.
								</label>
								<div class="flex gap-4">
									<div for="upateActiveTeams" class="text-sm font-medium leading-5 text-gray-700">
										Yes
										<input
											type="radio"
											v-model="upateActiveTeams"
											:value="true"
											class="form-radio h-4 w-4 text-ob-purple-color transition duration-150 ease-in-out cursor-pointer"
										/>
									</div>
									<div for="upateActiveTeams" class="text-sm font-medium leading-5 text-gray-700">
										No
										<input
											v-model="upateActiveTeams"
											:value="false"
											type="radio"
											class="form-radio h-4 w-4 text-ob-purple-color transition duration-150 ease-in-out cursor-pointer"
										/>
									</div>
								</div>
								<!-- class="form-input block w-full border border-gray-200 transition duration-150 ease-in-out sm:text-sm sm:leading-5" -->
							</div>
							<!--  -->
						</div>
					</div>
					<!--  -->
					<div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
						<button
							@click.prevent="handleUpdate"
							:class="(updating || !limit) && 'pointer-events-none opacity-25'"
							type="button"
							class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
						>
							<span>{{ updating ? "Updating..." : "Update" }} </span>
						</button>
						<button
							@click="closeModal"
							type="button"
							class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-100 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
						>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref } from "vue";
import { autoPick } from "../../utils";
import { handleGlobalAlert } from "../connectButtons/shared";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { listAllPaymentTiersQuery } from "../../graphql/billing/query";
import { updatePaymentTierMutation } from "../../graphql/billing/mutation";

export default {
	name: "UpdateTierModal",
	setup() {
		const upateActiveTeams = ref(false);
		const store = useStore();
		const selectedPlan = ref("");
		const localLimit = ref(undefined);
		const localPrice = ref(undefined);
		const show = computed(() => store.state.updateTierModal);
		const selectedTier = computed(() => tiers.value.find((x) => x.tier === selectedPlan.value));
		const limit = computed({
			get() {
				return selectedTier.value?.secretProcessLimit || 0;
			},
			set(newValue) {
				localLimit.value = Number(newValue);
			},
		});

		const price = computed({
			get() {
				return selectedTier.value?.pricePerUser || 0;
			},
			set(newValue) {
				localPrice.value = Number(newValue);
			},
		});

		function resetValues() {
			localPrice.value = undefined;
			localLimit.value = undefined;
		}

		const closeModal = () => {
			store.commit("setUpdateTierModal", { state: false });
		};

		const { result, loading } = useQuery(listAllPaymentTiersQuery);
		const tiers = computed(() => autoPick(result.value));

		const filteredTiers = computed(() => {
			return tiers.value.filter((tier) => tier.tier !== "ENTERPRISE");
		});

		const { mutate: updatePaymentTier, loading: updating } = useMutation(updatePaymentTierMutation);
		const handleUpdate = async () => {
			if (!selectedTier.value || (localLimit.value === undefined && localPrice.value === undefined))
				return;

			const editPaymentTierInput = {
				tier: selectedPlan.value,
				updateActiveOrgsPlan: upateActiveTeams.value,
			};

			if (
				Number(selectedTier.value.secretProcessLimit) !== localLimit.value &&
				localLimit.value !== undefined
			) {
				editPaymentTierInput.secretProcessLimit = localLimit.value;
			}

			if (
				Number(selectedTier.value.pricePerUser) !== localPrice.value &&
				localPrice.value !== undefined
			) {
				editPaymentTierInput.pricePerUser = localPrice.value;
			}

			try {
				await updatePaymentTier({ editPaymentTierInput });
				handleGlobalAlert(true, "success", "Tier Updated");
				closeModal();
			} catch (error) {
				handleGlobalAlert(true, "error", error.message);
			}
		};

		return {
			resetValues,
			localLimit,
			localPrice,
			show,
			tiers,
			limit,
			price,
			loading,
			updating,
			closeModal,
			handleUpdate,
			selectedTier,
			selectedPlan,
			filteredTiers,
			upateActiveTeams,
		};
	},
};
</script>

<style lang="scss" scoped>
.fadeIn-enter-active,
.fadeIn-leave-active {
	.main {
		transition: all 0.3s ease-in-out;
	}
}
.fadeIn-leave-to,
.fadeIn-enter-from {
	.main {
		transform: scale(0.8);
		opacity: 0;
	}
}
</style>
