const userInitials = (name) => {
	if (name) {
		let names = name.split(" ");
		if (names.length > 1) return names[0][0] + names[1][0];
		if (!names[0]) return "";
		return names[0][0];
	}
};

function is_url(str) {
	const regexp =
		/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
	if (regexp.test(str)) {
		return true;
	} else {
		return false;
	}
}

function autoPick(value = {}) {
	const keys = Object.keys(value);
	if (keys.length === 1) {
		return value[keys[0]];
	} else {
		return value;
	}
}

function exportToCsv(table, separator = ",") {
	// Select rows from table_id
	var rows = document.querySelectorAll("#" + table + " tr");
	// Construct csv
	var csv = [];
	for (var i = 0; i < rows.length; i++) {
		var row = [],
			cols = rows[i].querySelectorAll("td, th");
		for (var j = 0; j < cols.length; j++) {
			// Clean innertext to remove multiple spaces and jumpline (break csv)
			var data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, "").replace(/(\s\s)/gm, " ");
			data = data.replace(/"/g, '""');
			// Push escaped string
			row.push('"' + data + '"');
		}
		csv.push(row.join(separator));
	}
	var csv_string = csv.join("\n");
	// Download it
	var filename = "OBB_user_table" + "_" + new Date().toLocaleDateString() + ".csv";
	var link = document.createElement("a");
	link.style.display = "none";
	link.setAttribute("target", "_blank");
	link.setAttribute("href", "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string));
	link.setAttribute("download", filename);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}

function roundToTwo(num) {
	return +(Math.round(num + "e+2") + "e-2");
}

function splitString(str) {
	const [start, end] = str.split(" - ");
	return [start, end];
}

function parsedData(users) {
	if (users === null) {
		return [];
	}
	return JSON.parse(users);
}

export { userInitials, is_url, autoPick, exportToCsv, roundToTwo, splitString, parsedData };
