<template>
	<div class="overview w-screen overflow-x-hidden">
		<div class="lg:px-12 bg-dark-color pb-20">
			<header class="py-10 md:py-20 px-0 md:px-5">
				<div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-28 flex justify-between items-center">
					<div class="w-full">
						<h1 class="md:text-5xl text-4xl leading-10 mb-2 font-bold text-white">
							<span class="text-ob-light-purple-color">Hi</span>
							{{ user ? user.name : "" }},
						</h1>
						<p class="text-white md:text-xl mb-6 md:mb-0 text-base">
							Here's
							<span class="text-ob-light-purple-color">Analytics</span> overview for you.
						</p>
					</div>
					<div class="flex md:bg-transparent py-4 px-5 md:py-0 md:px-0 rounded leading-8">
						<div class="flex flex-col md:mx-10">
							<span class="text-gray-400 uppercase text-xs font-semibold">Pulls</span>
							<span class="text-ob-yellow-color font-bold text-3xl md:text-5xl stat">
								{{ analytics?.totalCount }}
							</span>
						</div>
					</div>
				</div>
			</header>
		</div>

		<!-- Activity list  -->
		<main class="-mt-20">
			<div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
				<div class="bg-white border border-gray-100 rounded-lg">
					<div class="flex flex-col">
						<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
								<div class="overflow-hidden sm:rounded-lg">
									<div
										class="bg-white px-4 py-5 border-b border-gray-100 sm:px-6 flex justify-between"
									>
										<div>
											<h3 class="text-lg leading-6 font-medium text-gray-900">Secret Pulls</h3>
											<p class="mt-1 text-sm leading-5 text-gray-500">
												List of all secret pulls on Onboardbase
											</p>
										</div>
										<div class="">
											<span class="inline-flex rounded-md">
												<router-link
													to="/analytics/userRetention"
													class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-ob-purple-color hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700"
													>View User Retention Data</router-link
												>
											</span>
										</div>
									</div>

									<div v-if="analyticsLoading && !analyticsError">
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
									</div>
									<error v-else-if="!analyticsLoading && analyticsError" />

									<empty
										v-else-if="analytics?.totalCount === 0"
										:message="{ text: `There's nothing here, yet` }"
									/>

									<table v-else class="min-w-full divide-y divide-gray-100" id="table">
										<thead>
											<tr>
												<th
													class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
												>
													Users
												</th>
												<th
													class="px-3 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
												>
													Total users Count
												</th>
												<th
													class="px-3 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
												>
													Org Name
												</th>
												<th
													class="px-3 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
												>
													Org Count
												</th>
												<th
													class="px-3 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
												>
													Date
												</th>
											</tr>
										</thead>
										<tbody
											v-if="!analyticsLoading && !analyticsError && !analytics?.length"
											class="bg-white divide-y divide-gray-200"
										>
											<!-- list -->
											<tr
												v-for="analytic in analytics.data"
												:key="analytic.id"
												class="cursor-pointer"
											>
												<td class="px-6 py-4 whitespace-no-wrap">
													<router-link :to="'/analytics/' + analytic.id" class="flex items-center">
														<div
															class="flex-shrink-0 h-10 w-10 uppercase bg-ob-purple-color text-white overflow-hidden rounded-md font-bold flex justify-center items-center"
														>
															{{ parsedData(analytic.users)[0].name[0] }}
														</div>
														<div class="ml-4">
															<div class="text-sm leading-5 font-medium text-gray-900">
																<span v-if="parsedData(analytic.users).length === 1">
																	{{ parsedData(analytic.users)[0].name }}
																</span>

																<span v-else> {{ parsedData(analytic.users)[0].name }} </span>
															</div>
															<div
																class="text-xs bg-gray-100 font-medium px-2 py-0.5 mt-0.5 rounded-md text-gray-500"
															>
																click to see more
															</div>
														</div>
													</router-link>
												</td>
												<td class="px-3 py-4 whitespace-no-wrap">
													<div class="flex items-center">
														<div class="">
															<div class="text-sm leading-5 font-medium text-gray-900">
																{{ analytic.count }}
															</div>
														</div>
													</div>
												</td>
												<td class="px-3 py-4 whitespace-no-wrap">
													<router-link
														:to="'/analytics/team/' + analytic.id"
														class="flex items-center"
													>
														<div class="">
															<div class="text-sm leading-5 font-medium text-gray-900">
																<span v-if="parsedData(analytic.team)?.length === 1">
																	{{ parsedData(analytic.team)[0].name }}
																</span>
																<span v-else> {{ parsedData(analytic.team)?.length }} team </span>
															</div>
															<div
																class="text-xs bg-gray-100 font-medium px-2 py-0.5 mt-0.5 rounded-md text-gray-500"
															>
																click to see more
															</div>
														</div>
													</router-link>
												</td>
												<td class="px-3 py-4 whitespace-no-wrap">
													<div class="flex items-center">
														<div class="">
															<div class="text-sm leading-5 font-medium text-gray-900">
																{{ analytic.teamCount }}
															</div>
														</div>
													</div>
												</td>

												<td class="px-3 py-4 whitespace-no-wrap">
													<div class="flex items-center">
														<div class="">
															<div class="text-sm leading-5 font-medium text-gray-900">
																{{ analytic.currentDay }}
															</div>
														</div>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
									<page-nav
										:class="analyticsLoading && 'pointer-events-none'"
										:totalCount="analytics?.totalCount"
										:nextPage="nextPage"
										:prev="prev"
										:next="next"
										:perPage="10"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { autoPick } from "@/utils/index";
import scrollToTop from "@/utils/scrollToTop";
import Error from "../../components/Error.vue";
import Empty from "../../components/Empty.vue";
import { useQuery } from "@vue/apollo-composable";
import { getAnalyticsDataQuery } from "../../graphql/query";
import PageNav from "../../components/pagination/PageNav.vue";
import ToolSkeleton from "../../components/skeleton/ToolSkeleton.vue";
import { useRouter } from "vue-router";
import { parsedData } from "../../utils";

export default {
	name: "Analytics",
	components: { PageNav, ToolSkeleton, Error, Empty },

	setup() {
		const store = useStore();
		const pattern = ref("");
		const nextPage = ref(0);
		const router = useRouter();
		const user = computed(() => store.state.user);

		const {
			result: analyticsResult,
			loading: analyticsLoading,
			error: analyticsError,
			variables,
		} = useQuery(getAnalyticsDataQuery, {
			take: 10,
			skip: 0,
		});

		const analytics = computed(() => autoPick(analyticsResult.value));

		// const apexData = computed(() => {
		// 	const categories = [];

		// 	const data = [];
		// 	const dataX = [];

		// 	if (analytics.value?.data) {
		// 		analytics.value.data.forEach((element) => {
		// 			categories.push(element.currentDay);
		// 			data.push(element.count);
		// 			dataX.push(element.teamCount);
		// 		});
		// 	}

		// 	return {
		// 		chartOptions: {
		// 			chart: { id: "user chart" },
		// 			title: {
		// 				text: "Total Secret Pull",
		// 				align: "left",
		// 			},
		// 			xaxis: { categories },
		// 			yaxis: {
		// 				title: {
		// 					text: "Count",
		// 				},
		// 			},
		// 		},

		// 		series: [
		// 			{ name: "User Secret Pull count", data },
		// 			{ name: "Team count", data: dataX },
		// 		],
		// 	};
		// });

		const updateVariable = () =>
			(variables.value = {
				skip: nextPage.value,
				name: pattern.value,
			});

		const next = () => {
			const limit = analytics.value.totalCount - (analytics.value.totalCount % 10);
			if (nextPage.value !== limit) {
				nextPage.value = nextPage.value + 10;
				updateVariable();
				scrollToTop();
			}
		};

		const prev = () => {
			if (nextPage.value > 0) {
				nextPage.value = nextPage.value - 10;
				updateVariable();
				scrollToTop();
			}
		};

		const routePage = (id) => {
			router.push(`/analytics/${id}`);
		};

		return {
			next,
			prev,
			user,
			routePage,
			nextPage,
			// apexData,
			analytics,
			parsedData,
			analyticsError,
			analyticsLoading,
		};
	},
};
</script>

<style></style>
