import { createApp, provide, h } from "vue";
import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, concat } from "@apollo/client";
import { DefaultApolloClient } from "@vue/apollo-composable";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/tailwind.css";
import "@/assets/scss/styles.scss";
import typeDefs from "./schema";
// import VCalendar from "v-calendar";
import VueApexCharts from "vue3-apexcharts";
import VueMarkdownIt from "vue3-markdown-it";
// import WokeLog from "@woke/log";
// new WokeLog.default();

const httpLink = new HttpLink({ uri: process.env.VUE_APP_API_SERVER });

const authMiddleware = new ApolloLink((operation, forward) => {
	// add the authorization to the
	const token = localStorage.getItem("o-token-ap");

	operation.setContext({
		headers: {
			authorization: (token && `Bearer ${token}`) || null,
		},
	});

	return forward(operation);
});

const defaultClient = new ApolloClient({
	link: concat(authMiddleware, httpLink),
	cache: new InMemoryCache(),
	typeDefs: typeDefs,
});

createApp({
	setup() {
		provide(DefaultApolloClient, defaultClient);
	},
	render() {
		return h(App);
	},
})
	.use(store)
	.use(router)
	.use(VueApexCharts)
	// .use(VCalendar, {})
	.use(VueMarkdownIt)
	.mount("#app");
