<template>
	<transition name="fadeIn">
		<div
			v-if="show.state"
			class="fixed z-50 inset-0 overflow-y-auto"
			:class="(removeTeamLoading || deleteBlogLoading) && 'pointer-events-none'"
		>
			<div
				class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
			>
				<div class="fixed inset-0 transition-opacity" aria-hidden="true">
					<div
						@click.self="closeModal"
						class="absolute inset-0 bg-gray-800 bg-opacity-75 modal-blur"
					></div>
				</div>
				<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"
					>&#8203;</span
				>

				<div
					class="main inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
					role="dialog"
					aria-modal="true"
					aria-labelledby="modal-headline"
				>
					<div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
						<button
							@click="closeModal"
							type="button"
							class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
						>
							<span class="sr-only">Close</span>
							<svg
								class="h-6 w-6"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
					<div class="sm:flex sm:items-start">
						<div
							class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
						>
							<!-- Heroicon name: exclamation -->
							<svg
								class="h-5 w-5 text-red-500"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
								/>
							</svg>
						</div>
						<div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
							<h3 class="text-lg leading-6 font-medium mb-4" id="modal-headline">
								Are you sure you want to delete {{ show.name || show.title }}?
							</h3>
							<div class="text-sm text-gray-500">
								<p>
									Everything associated with <b>{{ show.name || show.title }}</b> will be deleted
									permanently.
								</p>
							</div>
						</div>
					</div>

					<div
						class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse"
						:class="(removeTeamLoading || deleteBlogLoading) && 'pointer-events-none'"
					>
						<button
							@click="activateDel"
							:disabled="removeTeamLoading || deleteBlogLoading"
							type="button"
							:class="(removeTeamLoading || deleteBlogLoading) && 'opacity-25 pointer-events-none'"
							class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
						>
							{{ removeTeamLoading || deleteBlogLoading ? "Deleting..." : "Delete" }}
						</button>
						<button
							@click="closeModal"
							type="button"
							:class="(removeTeamLoading || deleteBlogLoading) && 'opacity-25 pointer-events-none'"
							class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-100 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
						>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useMutation } from "@vue/apollo-composable";
import { removeTeamMutation, deleteBlogMutation } from "@/graphql/mutation";
import { handleGlobalAlert } from "../connectButtons/shared";

export default {
	name: "Team modal",
	props: {
		id: String,
		forBlog: Boolean,
		forTeam: Boolean,
	},
	setup() {
		const store = useStore();
		const show = computed(() => store.state.deleteTeamModal);
		const { mutate: removeTeam, loading: removeTeamLoading } = useMutation(removeTeamMutation);
		const { mutate: deleteBlog, loading: deleteBlogLoading } = useMutation(deleteBlogMutation);
		const closeModal = () => {
			store.commit("setDeleteTeamModal", { state: false });
		};

		const activateDel = async () => {
			if (show.value.forTeam) {
				try {
					removeTeam({ teamId: show.value.id }).then(() => {
						show.value.refetch();
						handleGlobalAlert(true, "success", "Organization removed successfully");
						closeModal();
					});
				} catch (error) {
					handleGlobalAlert(true, "failed", `Error deleting ${show.value.name} , try again.`);
				}
				return;
			}

			if (show.value.forBlog) {
				try {
					const res = await deleteBlog({
						blogId: show.value.id,
					});
					if (res.data) {
						show.value.refetch();
						handleGlobalAlert(true, "success", `${show.value.title} deleted.`);
						closeModal();
					}
				} catch (error) {
					handleGlobalAlert(true, "failed", `Error deleting ${show.value.title}.`);
					closeModal();
				}
				return;
			}
		};

		return {
			show,
			closeModal,
			removeTeam,
			deleteBlog,
			activateDel,
			removeTeamLoading,
			deleteBlogLoading,
		};
	},
};
</script>

<style lang="scss" scoped>
.fadeIn-enter-active,
.fadeIn-leave-active {
	.main {
		transition: all 0.3s ease-in-out;
	}
}
.fadeIn-leave-to,
.fadeIn-enter-from {
	.main {
		transform: scale(0.8);
		opacity: 0;
	}
}
</style>
