<template>
	<div class="flex items-center sm:px-6 my-4">
		<div class="min-w-0 flex-1 flex items-center animate-pulse">
			<div class="flex-shrink-0 rounded-lg overflow-hidden bg-gray-200">
				<div class="h-10 w-10 object-contain" />
			</div>
			<div class="min-w-0 h-10 flex-1 px-4 pr-10 md:grid-cols-2 md:gap-4">
				<div>
					<div
						class="text-base rounded-sm w-40 bg-gray-200 px-2 py-2 leading-5 font-semibold truncate"
					></div>
					<div class="flex items-center px-2 py-2 text-sm mt-2 leading-5 anim bg-gray-200"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ToolSkeleton",
};
</script>

<style>
/* @keyframes pulse-bg {
  0% {
    background-color: #ddd;
  }
  50% {
    background-color: #d0d0d0;
  }
  100% {
    background-color: #ddd;
  }
}

.anim {
  animation-name: pulse-bg;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
.anim2 {
  animation-name: pulse-bg;
  animation-duration: .7s;
  animation-iteration-count: infinite;
} */
</style>
