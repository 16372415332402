<template>
	<modal-wrapper :class="creating && 'pointer-events-none'" @closeModal="() => $emit('closeModal')">
		<template v-slot:modal-content>
			<div
				class="main inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-xl sm:w-full"
				role="dialog"
				aria-modal="true"
				aria-labelledby="modal-headline"
			>
				<div class="sm:flex flex-wrap sm:items-start w-full">
					<div class="text-center sm:text-left w-full">
						<header class="space-y-1 py-2 px-4 bg-dark-color sm:px-6">
							<div class="flex items-center justify-between space-x-3">
								<div class="flex flex-col py-2">
									<h2 class="text-lg leading-7 text-white font-medium">
										Create Enterprise Plan for
										<span class="text-ob-purple-color uppercase"
											>{{ modal?.team?.name || "Organization" }}
										</span>
									</h2>
									<p class="text-sm leading-5 text-gray-400">
										Create enterprise price details for customers
									</p>
								</div>
								<div class="h-7 flex items-center">
									<button
										@click="$emit('closeModal')"
										aria-label="Close panel"
										class="text-indigo-200 hover:text-white transition ease-in-out duration-150 focus:outline-none"
									>
										<!-- Heroicon name: x -->
										<svg
											class="h-5 w-5"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M6 18L18 6M6 6l12 12"
											/>
										</svg>
									</button>
								</div>
							</div>
						</header>
						<!-- AMOUNT -->
						<div
							class="w-full p-4 sm:px-6 flex flex-col gap-5 py-7"
							:class="creating && 'opacity-25'"
						>
							<div class="w-full flex gap-4 items-center">
								<div class="flex-1">
									<label for="amount" class="block text-sm font-medium leading-5 text-gray-700">
										Plan Amount ($)
									</label>
									<div class="mt-1 rounded-md">
										<input
											v-model.number="amount"
											id="amount"
											type="number"
											placeholder="Cost of Plan"
											class="form-input block w-full border border-gray-200 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
										/>
									</div>
								</div>
								<!--  -->
								<label for="perUser" class="flex gap-2 mt-6 cursor-pointer mr-4">
									<input
										type="checkbox"
										class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
										name="perUser"
										id="perUser"
										v-model="perUser"
									/>
									<span for="users" class="block text-sm font-medium leading-5 text-gray-700">
										Per User?
									</span>
								</label>
							</div>

							<!-- BILLING FREQUENCY -->
							<div :class="creating && 'opacity-25'" class="w-full">
								<label
									for="billingFrequency"
									class="block text-sm font-medium leading-5 text-gray-700"
								>
									Billing Frequency
								</label>
								<div class="mt-1 rounded-md">
									<select
										id="billingFrequency"
										class="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-5 border-gray-200 focus:outline-none sm:text-sm sm:leading-5"
										v-model="interval"
									>
										<option disabled value="">Select Billing Frequency</option>
										<option value="month">Monthly</option>
										<option value="year">Yearly</option>
									</select>
								</div>
							</div>
							<!-- USAGE LIMIT -->
							<div :class="creating && 'opacity-25'" class="w-full">
								<label
									for="billingFrequency"
									class="block text-sm font-medium leading-5 text-gray-700"
								>
									Usage Limit
								</label>
								<div class="mt-1 rounded-md">
									<input
										v-model.number="secretProcessLimit"
										id="secretProcessLimit"
										type="number"
										placeholder="Usage Limit"
										class="form-input block w-full border border-gray-200 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
									/>
								</div>
							</div>
							<div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
								<button
									@click.prevent="handlePlan"
									:class="
										(creating || !secretProcessLimit || !interval || !amount) &&
										'pointer-events-none opacity-25'
									"
									type="button"
									class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
								>
									<span>{{ creating ? "Creating..." : "Create" }} </span>
								</button>
								<button
									@click="closeModal"
									type="button"
									class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-100 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
								>
									Cancel
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
	</modal-wrapper>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useMutation } from "@vue/apollo-composable";
import { createEnterpriseForTeamMutation } from "../../graphql/billing/mutation";
import { handleGlobalAlert } from "../connectButtons/shared";
import ModalWrapper from "./ModalWrapper.vue";

export default {
	components: { ModalWrapper },
	props: { modal: Object },
	emits: ["closeModal"],
	name: "PlanModal",
	setup(props, { emit }) {
		const formData = reactive({
			perUser: false,
			amount: 0,
			interval: "month",
			secretProcessLimit: 0,
		});

		const { mutate: createEnterprisePrice, loading: creating } = useMutation(
			createEnterpriseForTeamMutation
		);
		const handlePlan = async () => {
			if (!formData.amount || !props.modal.team) {
				return handleGlobalAlert(true, "failed", "Please enter all required fields");
			}
			try {
				await createEnterprisePrice({
					createEnterprisePlanInput: {
						teamId: props.modal.team.id,
						perUser: formData.perUser,
						interval: formData.interval,
						amount: formData.amount,
						secretProcessLimit: formData.secretProcessLimit,
					},
				});
				handleGlobalAlert(true, "success", "Enterprise price created");
				emit("closeModal", true);
			} catch (error) {
				handleGlobalAlert(true, "success", error.message);
			}
		};

		return {
			creating,
			handlePlan,
			...toRefs(formData),
		};
	},
};
</script>

<style lang="scss" scoped>
.fadeIn-enter-active,
.fadeIn-leave-active {
	.main {
		transition: all 0.3s ease-in-out;
	}
}
.fadeIn-leave-to,
.fadeIn-enter-from {
	.main {
		transform: scale(0.8);
		opacity: 0;
	}
}
</style>
