<template>
	<transition name="slideDown">
		<div
			v-if="status !== null"
			class="main fixed z-50 bottom-5 right-0 mr-6 min-w-min-content flex sm:max-w-md m-auto over rounded-md p-3"
			:class="status ? 'bg-green-100' : 'bg-red-50'"
		>
			<div class="flex">
				<div class="flex-shrink-0 cursor-pointer">
					<!-- Heroicon name: x-circle -->
					<svg
						viewBox="0 0 40.771 40.771"
						v-if="!status"
						class="h-5 w-5 text-red-500"
						fill="currentColor"
					>
						<g>
							<g id="_x31_03_65_">
								<g>
									<path
										d="M39.9,29.212c-1.084-1.084-2.169-2.169-3.253-3.255l4.124-4.122l-1.686-1.688l-4.124,4.125     c-1.096-1.096-2.19-2.19-3.286-3.285c-0.071-0.072-0.146-0.13-0.221-0.178l-0.673-0.673l-1.692,1.692l4.157,4.157l-4.016,4.018     l1.687,1.686l4.017-4.017l4.049,4.05l1.692-1.693l-0.597-0.596C40.029,29.356,39.972,29.282,39.9,29.212z"
									/>
									<path
										d="M33.928,16.134c0.229,0,0.457,0.019,0.684,0.034c0.032-0.226,0.058-0.453,0.069-0.682     c-0.224-4.134-3.166-7.074-7.297-7.297c-2.424-0.13-4.445,1.107-5.742,2.917C20.346,9.4,18.33,8.321,15.895,8.189     c-4.123-0.222-7.09,3.5-7.295,7.297c-0.023,0.488,0.008,0.957,0.084,1.411c-0.258-0.037-0.521-0.062-0.789-0.077     c-4.457-0.24-7.664,3.783-7.885,7.885c-0.227,4.193,3.32,7.279,7.158,7.813v0.073h19.554c-1.613-1.749-2.607-4.078-2.607-6.646     C24.115,20.527,28.51,16.134,33.928,16.134z"
									/>
								</g>
							</g>
						</g>
					</svg>
					<svg
						v-else
						viewBox="0 0 47.855 47.855"
						style="enable-background: new 0 0 47.855 47.855"
						class="h-5 w-5 text-green-500"
						fill="currentColor"
					>
						<g>
							<g>
								<path
									style="fill: inherit"
									d="M23.928,19.371c-5.675,0-10.866,2.886-13.885,7.719c-0.731,1.171-0.375,2.713,0.796,3.445    c1.17,0.73,2.713,0.375,3.445-0.797c2.099-3.361,5.705-5.367,9.644-5.367s7.544,2.006,9.644,5.367    c0.474,0.76,1.289,1.177,2.122,1.177c0.453,0,0.91-0.123,1.322-0.38c1.171-0.731,1.527-2.273,0.796-3.445    C34.794,22.257,29.604,19.371,23.928,19.371z"
								/>
								<path
									style="fill: inherit"
									d="M47.476,21.07C42.365,12.883,33.562,7.996,23.928,7.996S5.492,12.883,0.38,21.07    c-0.731,1.171-0.375,2.713,0.796,3.444c1.172,0.73,2.714,0.375,3.445-0.796c4.192-6.714,11.41-10.722,19.307-10.722    s15.115,4.008,19.307,10.722c0.474,0.759,1.289,1.176,2.122,1.176c0.452,0,0.91-0.122,1.322-0.38    C47.851,23.782,48.207,22.241,47.476,21.07z"
								/>
								<circle style="fill: inherit" cx="23.928" cy="35.745" r="4.114" />
							</g>
						</g>
					</svg>
				</div>

				<!-- :class="status.type === 'success' ? 'text-green-700' : 'text-red-700'" -->
				<div class="ml-3">
					<div class="text-sm leading-5">
						{{
							status === true
								? "You are now Online!"
								: "You are Offline, please restore your internet connection"
						}}
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
	name: "Internet status popup",

	setup() {
		const store = useStore();
		const status = computed(() => store.state.online);
		// const close = () => store.commit("setOnline", false);

		return { status };
	},
};
</script>

<style lang="scss" scoped>
.slideDown-enter-active,
.slideDown-leave-active {
	transition: all 0.2s ease-out;
}
.slideDown-leave-to,
.slideDown-enter-from {
	opacity: 0;
	transform: translateY(-100%);
}
</style>
