<template>
	<modal-wrapper
		:class="addingNonPaymentPlan && 'pointer-events-none'"
		@closeModal="() => $emit('closeModal')"
	>
		<template v-slot:modal-content>
			<div
				class="main inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-xl sm:w-full"
				role="dialog"
				aria-modal="true"
				aria-labelledby="modal-headline"
			>
				<div class="text-center sm:text-left w-full">
					<header class="space-y-1 py-2 px-4 bg-dark-color sm:px-6">
						<div class="flex items-center justify-between space-x-3">
							<div class="flex flex-col py-2">
								<h2 class="text-lg leading-7 text-white font-medium">
									Non-payment plan for
									<span class="text-ob-purple-color uppercase"
										>{{ modal?.team?.name || "Organization" }}
									</span>
								</h2>
								<p class="text-sm leading-5 text-gray-400">Change plan without payment</p>
							</div>
							<div class="h-7 flex items-center">
								<button
									@click="$emit('closeModal')"
									aria-label="Close panel"
									class="text-indigo-200 hover:text-white transition ease-in-out duration-150 focus:outline-none"
								>
									<!-- Heroicon name: x -->
									<svg
										class="h-5 w-5"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M6 18L18 6M6 6l12 12"
										/>
									</svg>
								</button>
							</div>
						</div>
					</header>

					<div
						class="w-full p-4 sm:px-6 flex flex-col gap-5 py-7"
						:class="addingNonPaymentPlan && 'opacity-25'"
					>
						<!-- BILLING FREQUENCY -->
						<div :class="false && 'opacity-25'" class="w-full">
							<label for="tier" class="block text-sm font-medium leading-5 text-gray-700">
								Plan
							</label>
							<div class="mt-1 rounded-md">
								<select
									id="tier"
									class="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-5 border-gray-200 focus:outline-none sm:text-sm sm:leading-5"
									placeholder="SELECT PLAN"
									v-model="plan"
								>
									<option value="STARTUP">STARTUP</option>
									<option value="BUSINESS">BUSINESS</option>
									<option value="ENTERPRISE">ENTERPRISE</option>
								</select>
							</div>
						</div>
						<!-- USAGE LIMIT -->
						<div v-if="!modal.team.teamPaymentTier?.notPaying" class="w-full">
							<label
								for="billingFrequency"
								class="block text-sm font-medium leading-5 text-gray-700"
							>
								Duration
								<span
									v-if="plan !== 'ENTERPRISE'"
									class="text-ob-purple-color uppercase text-xs font-bold"
									>(months)</span
								>
							</label>
							<div
								v-if="
									!(
										modal.team.paymentTier.tier === 'ENTERPRISE' &&
										modal.team?.teamPaymentTier?.notPaying
									) && plan === 'ENTERPRISE'
								"
								class="flex my-2 mb-3 gap-2"
							>
								<button
									class="p-1 px-2 border rounded-xl text-sm"
									@click="interval = 'mo'"
									:class="
										interval === 'mo' ? 'bg-ob-purple-color text-white' : 'text-ob-purple-color'
									"
								>
									month
								</button>
								<button
									@click="interval = 'yr'"
									:class="
										interval === 'yr' ? 'bg-ob-purple-color text-white' : 'text-ob-purple-color'
									"
									class="p-1 px-2 border rounded-xl text-sm"
								>
									year
								</button>
							</div>
							<div class="mt-1 rounded-md flex flex-col gap-2">
								<input
									v-model.number="month"
									id="month"
									type="number"
									class="form-input block w-full border border-gray-200 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
								/>
								<span class="text-xs text-dark-color">Set to 0 for indefinite months</span>
							</div>
						</div>
						<!-- USAGE LIMIT -->
						<div
							v-if="
								!(
									modal.team.paymentTier.tier === 'ENTERPRISE' &&
									modal.team?.teamPaymentTier?.notPaying
								) && plan === 'ENTERPRISE'
							"
							class="w-full"
						>
							<label
								for="billingFrequency"
								class="block text-sm font-medium leading-5 text-gray-700"
							>
								Secret usage limit
							</label>
							<div class="mt-1 rounded-md flex">
								<input
									v-model.number="secretUsageLimit"
									id="month"
									type="number"
									placeholder="Usage Limit"
									class="form-input block w-full border border-gray-200 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
								/>
							</div>
						</div>

						<div
							:class="addingNonPaymentPlan && 'pointer-events-none opacity-25'"
							class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse"
						>
							<button
								@click.prevent="handlePlan"
								:class="
									((modal.team.teamPaymentTier?.notPaying &&
										modal.team.paymentTier.tier === plan) ||
										!plan ||
										(plan === 'ENTERPRISE' && !secretUsageLimit)) &&
									'pointer-events-none opacity-25'
								"
								type="button"
								class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
							>
								<span>{{ addingNonPaymentPlan ? "Loading..." : "Create" }} </span>
							</button>
							<button
								@click="$emit('closeModal')"
								type="button"
								class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-100 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
							>
								Cancel
							</button>
						</div>
					</div>
				</div>
			</div>
		</template>
	</modal-wrapper>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue";
import ModalWrapper from "./ModalWrapper.vue";
import { addNonPaymentPlanMutation } from "../../graphql/billing/mutation";
import { useMutation } from "@vue/apollo-composable";
import { handleGlobalAlert } from "../connectButtons/shared";
export default {
	components: { ModalWrapper },
	props: { modal: Object },
	emit: ["closeModal"],
	setup(props, { emit }) {
		const { mutate: addNonPaymentPlan, loading: addingNonPaymentPlan } =
			useMutation(addNonPaymentPlanMutation);

		const formData = reactive({
			month: 1,
			plan: null,
			secretUsageLimit: 0,
			interval: "mo",
		});

		const handlePlan = async () => {
			if (!formData.plan || !props.modal.team) {
				return handleGlobalAlert(true, "failed", "Please enter all required fields");
			}
			const input = {
				teamId: props.modal.team.id,
				paymentTier: formData.plan,
				secretUsageLimit: formData.secretUsageLimit,
			};
			if (formData.plan !== "ENTERPRISE" || props.modal.team.teamPaymentTier?.notPaying) {
				input.interval = "mo";
			} else {
				input.interval = formData.interval;
			}
			if (!props.modal.team.teamPaymentTier?.notPaying) {
				input.monthOrYear = formData.month;
			}

			try {
				await addNonPaymentPlan(input);
				handleGlobalAlert(true, "success", "Enterprise price created");
				emit("closeModal", true);
			} catch (error) {
				handleGlobalAlert(true, "failed", error.message);
			}
		};
		onMounted(() => {
			if (props.modal.team?.teamPaymentTier?.notPaying) {
				formData.plan = props.modal.team.paymentTier.tier;
			}
		});
		return {
			addingNonPaymentPlan,
			handlePlan,
			...toRefs(formData),
		};
	},
};
</script>

<style></style>
