<template>
	<router-link
		:to="link.to"
		exact
		class="px-3 py-2 rounded-md text-sm font-medium text-ob-gray-color hover:text-white hover:bg-gray-800 focus:outline-none focus:text-white focus:bg-black relative"
	>
		<span>{{ link.name }}</span>
	</router-link>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
export default {
	name: "NavLink",
	props: {
		link: Object,
		currentTeam: Object,
		role: Object,
	},

	setup() {
		const store = useStore();
		const route = useRoute();
		const orgD = computed(() => store.state.ordDropdown);
		const setupD = computed(() => store.state.setupDropdown);
		const linkDescription = ref("");
		const name = computed(() => store.state.user.name);
		const currentRoute = computed(() => route.path);
		const teamRoutes = ["tools", "employees"];
		const currentTeam = computed(() => store.state.currentTeam);
		const currentTeamRoute = computed(() => {
			const urls = [
				`/team/${currentTeam.value.slug}/tools`,
				`/team/${currentTeam.value.slug}/employees`,
			];
			let d = route.path.split("/");
			if (urls.includes(route.path)) {
				return d[d.length - 1];
			}
			return null;
		});

		const setOrgD = () => {
			setSetupDD(false);
			// store.commit("setOrgDropdown", !orgD.value);
		};
		// const setOrgDD = d => {
		// 	store.commit("setOrgDropdown", d);
		// };

		const setSetupD = () => {
			// setOrgDD(false);
			store.commit("setSetupDropdown", !setupD.value);
		};
		const setSetupDD = (d) => {
			store.commit("setSetupDropdown", d);
		};
		return {
			orgD,
			setOrgD,
			// setOrgDD,
			setupD,
			setSetupD,
			setSetupDD,
			linkDescription,
			name,
			currentRoute,
			currentTeamRoute,
			teamRoutes,
		};
	},
};
</script>

<style scoped>
.router-link-exact-active {
	@apply text-white;
	@apply bg-black;
}
.activee {
	@apply text-black;
	@apply bg-gray-100;
}
</style>
