<template>
	<div class="overview w-screen overflow-x-hidden">
		<div class="lg:px-12 bg-dark-color pb-20">
			<header class="py-10 md:py-20 px-0 md:px-5">
				<div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-28 flex justify-between items-center">
					<div class="w-full">
						<h1 class="md:text-5xl text-4xl leading-10 mb-2 font-bold text-white">
							<span class="text-ob-light-purple-color">Hi</span>
							{{ user ? user.name : "" }},
						</h1>
						<p class="text-white md:text-xl mb-6 md:mb-0 text-base">
							Here's a collection of
							<span class="text-ob-light-purple-color">Plans</span> for you.
						</p>
					</div>
					<!-- <div class="flex py-4 px-5 md:py-0 md:px-0 rounded leading-8">
						<div class="flex flex-col md:mx-10">
							<span class="text-gray-400 uppercase text-xs font-semibold">Plans</span>
							<span class="text-ob-yellow-color font-bold text-3xl md:text-5xl stat"> 30 </span>
						</div>
						<div class="flex flex-col md:mx-10">
							<span class="text-gray-400 uppercase text-xs font-semibold">Coupons</span>
							<span class="text-ob-yellow-color font-bold text-3xl md:text-5xl stat"> 50 </span>
						</div>
					</div> -->
				</div>
			</header>
		</div>

		<!-- Activity list  -->
		<main class="-mt-20">
			<div class="max-w-7xl mx-auto pb-12 flex flex-col gap-10">
				<!-- ENTERPRISE -->
				<teams-with-plan />
				<!-- <enterprice-teams /> -->
				<!-- COUPON -->
				<!-- <div class="bg-white mt-5 border border-gray-100 rounded-lg">
					<div class="flex flex-col">
						<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 w-full">
								<div class="overflow-hidden sm:rounded-lg">
									<div
										class="bg-white justify-between flex px-4 py-5 border-b border-gray-100 sm:px-6"
									>
										<div class="">
											<h3 class="text-lg leading-6 font-medium text-gray-900">Coupons</h3>
											<p class="mt-1 text-sm leading-5 text-gray-500">List of Coupons</p>
										</div>
										<div class="flex">
											<div class="ml-4 flex-shrink-0">
												<span class="inline-flex rounded-md">
													<a href="#" class="flex justify-center" @click.prevent="createCoupon">
														<span
															class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-ob-purple-color hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700"
															>Create Coupon</span
														>
													</a>
												</span>
											</div>
										</div>
									</div>

									<table class="min-w-full divide-y divide-gray-100">
										<thead>
											<tr>
												<th
													class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
												>
													Coupon
												</th>
												<th
													class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
												>
													Percentage Off
												</th>

												<th
													class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
												>
													Date Added
												</th>
											</tr>
										</thead>

										<tbody class="bg-white divide-y divide-gray-100">
											<tr class="cursor-pointer hover:bg-gray-100">
												<td class="px-6 py-4 whitespace-no-wrap">
													<div class="flex items-center">
														<div
															class="flex-shrink-0 h-10 w-10 uppercase bg-ob-purple-color text-white overflow-hidden rounded-md font-bold flex justify-center items-center"
														>
															C
														</div>
														<div class="ml-4 cursor-pointer">
															<div class="text-sm leading-5 capitalize font-medium text-gray-900">
																code
															</div>
														</div>
													</div>
												</td>
												<td class="px-6 py-4 whitespace-no-wrap">
													<div class="flex items-center">
														<div class="ml-4">
															<div class="text-sm leading-5 capitalize font-medium text-gray-900">
																50%
															</div>
														</div>
													</div>
												</td>
												<td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium">
													08-08-88
												</td>

												<td
													class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium flex items-center justify-end"
												>
													<span
														class="cursor-pointer ml-3 relative inline-flex items-center px-3 py-2 text-sm leading-5 font-medium rounded-md text-gray-400 hover:text-red-500 focus:outline-none transition ease-in-out duration-150"
													>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															class="h-5 w-5"
															viewBox="0 0 20 20"
															fill="currentColor"
														>
															<path
																fill-rule="evenodd"
																d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
																clip-rule="evenodd"
															/>
														</svg>
													</span>
												</td>
											</tr>
										</tbody>
									</table>
									<page-nav :nextPage="nextPage" :prev="prev" :next="next" :perPage="10" />
								</div>
							</div>
						</div>
					</div>
				</div> -->
				<!-- usage -->
				<usage-billing />
			</div>
		</main>
	</div>
</template>

<script>
import { useStore } from "vuex";

import { computed } from "vue";
// import PageNav from "@/components/pagination/PageNav";
// import EnterpriceTeams from "../../components/EnterpriceTeams.vue";
import UsageBilling from "../../components/UsageBilling.vue";
import TeamsWithPlan from "../../components/TeamsWithPlan.vue";

export default {
	name: "Plans",
	components: {
		// Empty,
		// Error,
		// PageNav,
		// EnterpriceTeams,
		UsageBilling,
		TeamsWithPlan,
	},

	setup() {
		// const pattern = ref("");
		// const nextPage = ref(0);
		const store = useStore();
		const user = computed(() => store.state.user);
		const currentTeam = computed(() => store.state.currentTeam);

		// onMounted(() => refetch());

		return {
			user,
			// deleteCoupon,
			currentTeam,
			// createCoupon,
			// showDeleteTeamModal,
		};
	},
};
</script>
