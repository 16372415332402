<template>
	<div class="overview w-screen overflow-x-hidden">
		<div class="lg:px-12 bg-dark-color pb-20">
			<header class="py-10 md:py-20 px-0 md:px-5">
				<div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-28 flex justify-between items-center">
					<div class="w-full">
						<h1 class="md:text-5xl text-4xl leading-10 mb-2 font-bold text-white">
							<span class="text-ob-light-purple-color">Hi</span>
							{{ user ? user.name : "" }},
						</h1>
						<p class="text-white md:text-xl mb-6 md:mb-0 text-base">
							Here's
							<span class="text-ob-light-purple-color">users</span> breakdown for you.
						</p>
					</div>
					<div class="flex md:bg-transparent py-4 px-5 md:py-0 md:px-0 rounded leading-8">
						<div class="flex flex-col md:mx-10">
							<span class="text-gray-400 uppercase text-xs font-semibold">Users</span>
							<span class="text-ob-yellow-color font-bold text-3xl md:text-5xl stat">
								{{ users?.totalCount }}
							</span>
						</div>
					</div>
				</div>
			</header>
		</div>

		<!-- Activity list  -->
		<main class="-mt-20">
			<div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
				<div class="bg-white border border-gray-100 rounded-lg">
					<div class="flex flex-col">
						<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
								<div class="overflow-hidden border-b border-gray-100 sm:rounded-lg">
									<div
										class="bg-white px-4 py-5 border-b border-gray-100 sm:px-6 flex justify-between"
									>
										<div>
											<h3 class="text-lg leading-6 font-medium text-gray-900">User</h3>
											<p class="mt-1 text-sm leading-5 text-gray-500">
												List of users on Onboardbase
											</p>
										</div>

										<button class="border-none focus:outline-none" @click="downloadAllData">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												class="w-4 h-4 mr-2 text-gray-400"
												viewBox="0 0 20 20"
												fill="currentColor"
											>
												<path
													fill-rule="evenodd"
													d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
													clip-rule="evenodd"
												/>
											</svg>
										</button>
									</div>
									<div v-if="usersLoading && !usersError">
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
									</div>
									<error v-else-if="!usersLoading && usersError" />
									<empty
										v-else-if="users?.teamUsers && users?.teamUsers?.length <= 0"
										:message="{ text: `There's nothing here, yet.` }"
									/>
									<table v-else class="min-w-full divide-y divide-gray-100" id="table">
										<thead>
											<tr>
												<th
													class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
												>
													Email
												</th>

												<th
													class="px-2 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
												>
													Status
												</th>
												<th
													class="px-3 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
												>
													Org
												</th>
												<th
													class="px-2 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
												>
													Role
												</th>
												<th
													class="px-2 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
												>
													Join date
												</th>
												<th
													class="px-2 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
												>
													Last login
												</th>
												<th
													class="px-2 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
												>
													Secret Pull
												</th>
											</tr>
										</thead>

										<tbody
											v-if="!usersLoading && !usersError && users?.teamUsers?.length"
											class="bg-white divide-y divide-gray-100"
										>
											<!-- search -->
											<!-- Users -->
											<tr v-for="user in users?.teamUsers" :key="user.id">
												<td class="px-6 py-4 whitespace-no-wrap">
													<div class="flex items-center">
														<div v-if="user.user.profilePhoto" class="flex-shrink-0 h-10 w-10">
															<img
																class="h-10 w-10 rounded-full object-contain object-center"
																:src="user.user.profilePhoto"
																:alt="user.user.name"
															/>
														</div>
														<div
															v-else
															class="flex-shrink-0 h-10 w-10 uppercase bg-ob-purple-color text-white overflow-hidden rounded-md font-bold flex justify-center items-center"
														>
															{{ user.user.email[0] }}
														</div>
														<div class="ml-4">
															<div class="text-sm leading-5 font-medium text-gray-900">
																{{ user.user.name }}
															</div>
															<div class="text-sm leading-5 text-gray-500">
																{{ user.user.email }}
															</div>
														</div>
													</div>
												</td>

												<td class="px-3 py-4 whitespace-no-wrap">
													<span
														class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
														:class="
															!user.user.isConfirmed || !user.user.active
																? 'bg-yellow-50 text-yellow-700'
																: 'bg-green-50 text-green-700'
														"
													>
														{{
															!user.user.isConfirmed || !user.user.active ? "Inactive" : "Active"
														}}
													</span>
												</td>
												<td class="px-3 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
													{{ user.team.name }}
												</td>
												<td class="px-3 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
													<span
														class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-indigo-50 text-indigo-500"
													>
														{{ user.userTeamRole?.name || `N/A` }}
													</span>
												</td>
												<td class="px-3 py-4 whitespace-no-wrap text-sm leading-5 font-medium">
													{{ user.user.createdDate.slice(0, 10) }}
												</td>
												<td class="px-3 py-4 whitespace-no-wrap text-sm leading-5 font-medium">
													{{ user.user.lastLogin?.slice(0, 10) }}
												</td>
												<td class="px-3 py-4 whitespace-no-wrap text-sm leading-5 font-medium">
													{{ user?.userCount || `N/A` }}
												</td>
											</tr>
										</tbody>
									</table>

									<!-- Pagination -->
									<page-nav
										:class="usersLoading && 'pointer-events-none'"
										:totalCount="users?.totalCount"
										:nextPage="nextPage"
										:prev="prev"
										:next="next"
										:perPage="10"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { dif_btw_dates } from "@/utils/date.js";
import { usersQuery } from "../../graphql/query";
import { useQuery, useResult } from "@vue/apollo-composable";
import PageNav from "@/components/pagination/PageNav";
import scrollToTop from "@/utils/scrollToTop";
import Error from "../../components/Error.vue";
import ToolSkeleton from "../../components/skeleton/ToolSkeleton.vue";
import Empty from "../../components/Empty.vue";
export default {
	name: "Home",
	components: {
		PageNav,
		Error,
		ToolSkeleton,
		Empty,
	},

	setup() {
		const store = useStore();
		const user = computed(() => store.state.user);
		const currentTeam = computed(() => store.state.currentTeam);
		const showNew = (d) => dif_btw_dates(d);
		const nextPage = ref(0);
		const pattern = ref("");

		const {
			result: usersResult,
			loading: usersLoading,
			variables,
			error: usersError,
			refetch,
		} = useQuery(usersQuery, {
			take: 10,
			skip: 0,
		});

		const users = useResult(usersResult);

		const updateVariable = () =>
			(variables.value = {
				id: user.value.id,
				skip: nextPage.value,
				name: pattern.value,
			});

		const next = () => {
			const limit = users.value.totalCount - (users.value.totalCount % 10);
			if (nextPage.value !== limit) {
				nextPage.value = nextPage.value + 10;
				updateVariable();
				scrollToTop();
			}
		};

		const prev = () => {
			if (nextPage.value > 0) {
				nextPage.value = nextPage.value - 10;
				updateVariable();
				scrollToTop();
			}
		};

		const search = () => {
			if (pattern.value.length === 0 || pattern.value.length >= 2) {
				nextPage.value = 0;
				updateVariable();
			}
		};

		function exportToCsv(table, separator = ",") {
			// Select rows from table_id
			var rows = document.querySelectorAll("#" + table + " tr");
			// Construct csv
			var csv = [];
			for (var i = 0; i < rows.length; i++) {
				var row = [],
					cols = rows[i].querySelectorAll("td, th");
				for (var j = 0; j < cols.length; j++) {
					const seperatedDiv = cols[j].innerText.split(/\r?\n/); // fseperate joined columns
					var data, data2;
					if (seperatedDiv.length > 1) {
						data = seperatedDiv[2] || seperatedDiv[1]; // return []
						data2 = seperatedDiv[1].replace(/"/g, '""');
					} else {
						// Clean innertext to remove multiple spaces and jumpline (break csv)
						data2 = undefined;
						data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, "").replace(/(\s\s)/gm, " ");
					}

					data = data.replace(/"/g, '""');
					// Push escaped string
					row.push('"' + data + '"');
					if ((i === 0 && data.toLowerCase().trim() === "email") || (data2 && j === 0)) {
						row.push('"' + (data2 || "NAME") + '"');
					}
				}
				csv.push(row.join(separator));
			}
			var csv_string = csv.join("\n");
			// Download it
			var filename = "OBB_user_table" + "_" + new Date().toLocaleDateString() + ".csv";
			var link = document.createElement("a");
			link.style.display = "none";
			link.setAttribute("target", "_blank");
			link.setAttribute("href", "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string));
			link.setAttribute("download", filename);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}

		async function downloadAllData() {
			await refetch({ skip: 0, take: users.value.totalCount });
			exportToCsv("table");
		}

		return {
			downloadAllData,
			exportToCsv,
			showNew,
			user,
			currentTeam,
			users,
			usersError,
			usersLoading,
			nextPage,
			pattern,
			search,
			next,
			prev,
		};
	},
};
</script>
