<template>
	<div class="overview w-screen overflow-x-hidden">
		<div class="lg:px-12 bg-dark-color pb-20">
			<header class="py-10 md:py-20 px-0 md:px-5">
				<div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-28 flex justify-between items-center">
					<div class="w-full">
						<h1 class="md:text-5xl text-4xl leading-10 mb-2 font-bold text-white">
							<span class="text-ob-light-purple-color">Hi</span>
							{{ user ? user.name : "" }},
						</h1>
						<p class="text-white md:text-xl mb-6 md:mb-0 text-base">
							Here's
							<span class="text-ob-light-purple-color">Analytics</span> overview for you.
						</p>
					</div>
				</div>
			</header>
		</div>

		<!-- Activity list  -->
		<main class="-mt-20">
			<div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
				<div class="bg-white border border-gray-100 rounded-lg">
					<div class="flex flex-col">
						<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
								<div class="overflow-hidden sm:rounded-lg">
									<div
										class="bg-white px-4 py-5 border-b border-gray-100 sm:px-6 flex justify-between"
									>
										<div class="flex items-center">
											<router-link to="/analytics" class="text-gray-500 hover:text-gray-600">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													class="h-5 w-5 text-gray-500"
													viewBox="0 0 20 20"
													fill="currentColor"
												>
													<path
														fill-rule="evenodd"
														d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
														clip-rule="evenodd"
													/>
												</svg>
											</router-link>
											<div class="ml-5">
												<h3 class="text-lg leading-6 font-medium text-gray-900">
													Team Secret Pulls
												</h3>
												<p class="mt-1 text-sm leading-5 text-gray-500">Team List</p>
											</div>
										</div>
									</div>

									<div v-if="analyticsLoading && !analyticsError">
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
									</div>
									<error v-else-if="!analyticsLoading && analyticsError" />

									<empty
										v-else-if="analytics?.totalCount === 0"
										:message="{ text: `There's nothing here, yet` }"
									/>

									<table v-else class="min-w-full divide-y divide-gray-100" id="table">
										<thead>
											<tr>
												<th
													class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
												>
													Org Name
												</th>
											</tr>
										</thead>
										<tbody
											v-if="!analyticsLoading && !analyticsError && !analytics?.length"
											class="bg-white divide-y divide-gray-200"
										>
											<!-- list -->
											<tr v-for="analytic in parsedData(analytics?.team)" :key="analytic.id">
												<td class="px-6 py-4 whitespace-no-wrap">
													<div class="flex items-center">
														<div
															class="flex-shrink-0 h-10 w-10 uppercase bg-ob-purple-color text-white overflow-hidden rounded-md font-bold flex justify-center items-center"
														>
															{{ analytic?.name[0] }}
														</div>
														<div class="ml-4">
															<div class="text-sm leading-5 font-medium text-gray-900">
																<span>
																	{{ analytic?.name }}
																</span>
															</div>
														</div>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import { useStore } from "vuex";
import Error from "../Error.vue";
import Empty from "../Empty.vue";
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import { autoPick } from "@/utils/index";
import { useQuery } from "@vue/apollo-composable";
import ToolSkeleton from "../skeleton/ToolSkeleton.vue";
import { getSingleAnalyticsDataQuery } from "../../graphql/query";
import { parsedData } from "../../utils";

export default {
	name: "Analytics",
	components: {
		ToolSkeleton,
		Error,
		Empty,
	},

	setup() {
		const nextPage = ref(0);
		const store = useStore();
		const route = useRoute();

		const user = computed(() => store.state.user);

		const {
			result: analyticsResult,
			loading: analyticsLoading,
			error: analyticsError,
		} = useQuery(getSingleAnalyticsDataQuery, {
			id: route.params.analyticId,
		});

		const analytics = computed(() => autoPick(analyticsResult.value));

		return {
			user,
			nextPage,
			analytics,
			parsedData,
			analyticsError,
			analyticsLoading,
		};
	},
};
</script>

<style></style>
