<template>
	<div class="overview w-screen overflow-x-hidden">
		<div class="lg:px-12 bg-dark-color pb-20">
			<header class="py-10 md:py-20 px-0 md:px-5">
				<div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-28 flex justify-between items-center">
					<div class="w-full">
						<h1 class="md:text-5xl text-4xl leading-10 mb-2 font-bold text-white">
							<span class="text-ob-light-purple-color">Hi</span>
							{{ user ? user.name : "" }},
						</h1>
						<p class="text-white md:text-xl mb-6 md:mb-0 text-base">
							Here's
							<span class="text-ob-light-purple-color">Subscribers</span> breakdown for you.
						</p>
					</div>
					<div class="flex md:bg-transparent py-4 px-5 md:py-0 md:px-0 rounded leading-8">
						<div class="flex flex-col md:mx-10">
							<span class="text-gray-400 uppercase text-xs font-semibold">Emails</span>
							<span class="text-ob-yellow-color font-bold text-3xl md:text-5xl stat">{{
								blogEmails?.totalCount
							}}</span>
						</div>
					</div>
				</div>
			</header>
		</div>

		<!-- Activity list  -->
		<main class="-mt-20">
			<div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
				<div class="bg-white border border-gray-100 rounded-lg">
					<div class="flex flex-col">
						<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
								<div class="overflow-hidden sm:rounded-lg">
									<div class="bg-white px-4 py-5 border-b border-gray-100 sm:px-6">
										<h3 class="text-lg leading-6 font-medium text-gray-900">Subscribers</h3>
										<p class="mt-1 text-sm leading-5 text-gray-500">List of subscribers</p>
									</div>
									<div v-if="blogEmailsLoading">
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
										<tool-skeleton />
									</div>

									<empty
										v-else-if="blogEmails?.totalCount === 0"
										:message="{ text: `No subscribers` }"
									/>
									<error v-else-if="blogEmailsError" />
									<table v-else class="min-w-full divide-y divide-gray-100">
										<thead>
											<tr>
												<th
													class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
												>
													Email
												</th>

												<th
													class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
												>
													Date added
												</th>
											</tr>
										</thead>

										<tbody class="bg-white divide-y divide-gray-100">
											<!-- search -->
											<!-- Users -->
											<tr v-for="blogEmail in blogEmails?.list" :key="blogEmail.id">
												<td class="px-6 py-4 whitespace-no-wrap">
													<div class="flex items-center">
														<div
															class="flex-shrink-0 h-10 w-10 uppercase bg-ob-purple-color text-white overflow-hidden rounded-md font-bold flex justify-center items-center"
														>
															{{ blogEmail.email[0] }}
														</div>
														<div class="ml-4">
															<div class="text-sm leading-5 font-medium text-gray-900">
																{{ blogEmail.email }}
															</div>
														</div>
													</div>
												</td>
												<td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium">
													{{ blogEmail.createdDate.slice(0, 10) }}
												</td>
											</tr>
										</tbody>
									</table>

									<!-- Pagination -->
									<page-nav
										v-if="blogEmails?.totalCount"
										:class="blogEmailsLoading && 'pointer-events-none'"
										:totalCount="blogEmails?.totalCount"
										:nextPage="nextPage"
										:prev="prev"
										:next="next"
										:perPage="10"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
// import Empty from "../../components/Empty";
import { dif_btw_dates } from "@/utils/date.js";
// import ChartLoader from "../../components/skeleton/ChartLoader.vue";
import { blogEmailsQuery } from "../../graphql/query";
import { useQuery, useResult } from "@vue/apollo-composable";
// import ToolSkeleton from "@/components/skeleton/ToolSkeleton";
import PageNav from "@/components/pagination/PageNav";
import scrollToTop from "@/utils/scrollToTop";
import Error from "../../components/Error.vue";
import ToolSkeleton from "../../components/skeleton/ToolSkeleton.vue";
import Empty from "../../components/Empty.vue";
export default {
	name: "Subscribers",
	components: {
		// Empty,
		// ToolSkeleton,
		// ChartLoader,
		PageNav,
		Error,
		ToolSkeleton,
		Empty,
	},

	setup() {
		const store = useStore();
		const user = computed(() => store.state.user);
		const currentTeam = computed(() => store.state.currentTeam);
		const showNew = (d) => dif_btw_dates(d);
		const nextPage = ref(0);
		const pattern = ref("");

		const {
			result: blogEmailsResult,
			loading: blogEmailsLoading,
			error: blogEmailsError,
			variables,
		} = useQuery(blogEmailsQuery, {
			take: 10,
			skip: 0,
		});

		const blogEmails = useResult(blogEmailsResult);

		const updateVariable = () =>
			(variables.value = {
				skip: nextPage.value,
				name: pattern.value,
			});

		const next = () => {
			const limit = blogEmails.value.totalCount - (blogEmails.value.totalCount % 10);
			if (nextPage.value !== limit) {
				nextPage.value = nextPage.value + 10;
				updateVariable();
				scrollToTop();
			}
		};

		const prev = () => {
			if (nextPage.value > 0) {
				nextPage.value = nextPage.value - 10;
				updateVariable();
				scrollToTop();
			}
		};

		const search = () => {
			if (pattern.value.length === 0 || pattern.value.length >= 2) {
				nextPage.value = 0;
				updateVariable();
			}
		};

		return {
			showNew,
			user,
			currentTeam,
			blogEmails,
			blogEmailsError,
			blogEmailsLoading,
			nextPage,
			pattern,
			search,
			next,
			prev,
		};
	},
};
</script>
