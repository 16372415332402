<template>
	<div class="overview w-screen overflow-x-hidden">
		<div class="lg:px-12 bg-dark-color pb-20">
			<header class="py-10 md:py-20 px-0 md:px-5">
				<div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-28 flex items-center justify-between">
					<div class="w-full">
						<h1 class="md:text-5xl text-4xl leading-10 mb-2 font-bold text-white">
							<span class="text-ob-light-purple-color">Hi</span>
							{{ user ? user.name : "" }},
						</h1>
						<p class="text-white md:text-xl mb-6 md:mb-0 text-base">
							Here's an
							<span class="text-ob-light-purple-color">overview</span> for you.
						</p>
					</div>
					<div class="flex py-4 px-5 md:py-0 md:px-0 rounded leading-8">
						<div class="flex flex-col md:mr-5">
							<span class="text-gray-400 uppercase text-xs font-semibold">Orgs.</span>
							<router-link :to="`/teams`">
								<span class="text-ob-yellow-color font-bold text-3xl md:text-5xl stat">
									{{ teams?.totalCount }}
								</span>
							</router-link>
						</div>
						<div class="flex flex-col md:mx-5">
							<span class="text-gray-400 uppercase text-xs font-semibold">Users</span>
							<router-link :to="`/users`">
								<span class="text-ob-yellow-color font-bold text-3xl md:text-5xl stat">
									{{ users?.totalCount }}
								</span>
							</router-link>
						</div>
						<!-- <div class="flex flex-col md:ml-5">
              <span class="text-gray-400 uppercase text-xs font-semibold"
                >Blogs</span
              >
              <router-link to="/subscriptions">
                <span
                  class="text-ob-yellow-color font-bold text-3xl md:text-5xl stat"
                  >{{ getBlogs?.totalCount }}</span
                >
              </router-link>
            </div> -->

						<!-- Sub -->
						<div class="flex flex-col md:ml-5">
							<span class="text-gray-400 uppercase text-xs font-semibold">Subscribers</span>
							<router-link :to="`/blogs`">
								<span class="text-ob-yellow-color font-bold text-3xl md:text-5xl stat">{{
									blogEmails?.totalCount
								}}</span>
							</router-link>
						</div>

						<!-- demo -->
						<!-- <div class="flex flex-col md:ml-5">
							<span class="text-gray-400 uppercase text-xs font-semibold"> Demos</span>
							<router-link :to="`/demos`">
								<span class="text-ob-yellow-color font-bold text-3xl md:text-5xl stat">{{
									getDemos?.totalCount
								}}</span>
							</router-link>
						</div> -->

						<!-- analytics  -->
						<div class="flex flex-col md:ml-5">
							<span class="text-gray-400 uppercase text-xs font-semibold">Analytics</span>
							<router-link :to="`/analytics`">
								<span class="text-ob-yellow-color font-bold text-3xl md:text-5xl stat">
									{{ analytics?.totalCount }}</span
								>
							</router-link>
						</div>
					</div>
				</div>
			</header>
		</div>

		<!-- Activity list Teams -->
		<div class="">
			<main class="-mt-20">
				<div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
					<div class="bg-white border border-gray-100 rounded-lg">
						<div class="flex flex-col">
							<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
								<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
									<div class="overflow-hidden sm:rounded-lg">
										<div class="bg-white px-4 py-5 border-b border-gray-100 sm:px-6">
											<h3 class="text-lg leading-6 font-medium">Organizations</h3>
											<p class="mt-1 text-sm leading-5 text-gray-500">
												List of Organizations on Onboardbase
											</p>
										</div>
										<table class="min-w-full divide-y divide-gray-100">
											<thead>
												<tr>
													<th
														class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
													>
														Name
													</th>
													<!-- <th
														class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
													>
														Tools
													</th> -->
													<th
														class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
													>
														Teammates
													</th>
													<th
														class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
													>
														Projects
													</th>
													<th
														class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
													>
														Join date
													</th>
													<th
														class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
													>
														Owner's Last login
													</th>
													<th
														class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
													>
														Secret Pull
													</th>
													<th
														class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
													>
														Hear About Us
													</th>
												</tr>
											</thead>

											<tbody
												v-if="!teamsLoading && !teamsError && teams?.teams?.length"
												class="bg-white divide-y divide-gray-100"
											>
												<!-- Teams -->
												<tr v-for="team in teams.teams.slice(0, 5)" :key="team.id">
													<td class="px-6 py-4 whitespace-no-wrap">
														<div class="flex items-center">
															<div
																v-if="team.logos"
																class="flex-shrink-0 h-10 w-10 overflow-hidden rounded-md"
															>
																<img
																	class="h-10 w-10 rounded-full object-contain object-center"
																	:src="team.logos"
																	alt=""
																/>
															</div>
															<div
																v-else
																class="flex-shrink-0 h-10 w-10 uppercase bg-ob-purple-color text-white overflow-hidden rounded-md font-bold flex justify-center items-center"
															>
																{{ team.name[0] }}
															</div>

															<div class="ml-4">
																<div class="text-sm leading-5 font-medium">
																	{{ team.name }}
																</div>
																<div class="text-sm leading-5 text-gray-500">
																	{{ getOwner(team.users.list)?.owner }}
																</div>
															</div>
														</div>
													</td>
													<!-- <td class="px-6 py-4 whitespace-no-wrap">
														<span
															class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full "
														>
															{{ team.tools.totalCount }}
														</span>
													</td> -->
													<td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
														{{ team.users.totalCount }}
													</td>
													<td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
														{{
															Number(getSecretCount(team.noOfTeamProjectsCount)["AK"]) +
															Number(getSecretCount(team.noOfTeamProjectsCount)["PW"])
														}}
														<span
															class="bg-indigo-50 text-ob-purple-color rounded-lg p-2 py-1 text-xs font-medium"
															>{{
																`${getSecretCount(team.noOfTeamProjectsCount)["AK"]} ENV, ${
																	getSecretCount(team.noOfTeamProjectsCount)["PW"]
																} LC`
															}}</span
														>
													</td>
													<td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium">
														{{ team.createdDate?.slice(0, 10) }}
													</td>
													<td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium">
														{{ getOwner(team.users.list)?.lastLogin?.slice(0, 10) }}
													</td>
													<td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium">
														{{ team?.totalTeamCount || "N/A" }}
													</td>
													<td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium">
														{{ team?.hearAboutUs || "N/A" }}
													</td>
												</tr>
											</tbody>

											<empty
												v-else-if="teams?.teams && teams?.teams?.length < 1"
												:message="{ text: `No organizations` }"
											/>
										</table>
										<error v-if="!teamsLoading && teamsError" />
										<div v-if="teamsLoading && !teamsError">
											<tool-skeleton />
											<tool-skeleton />
											<tool-skeleton />
											<tool-skeleton />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>

		<!-- Activity list: Users -->
		<div class="">
			<main class="mt-5">
				<div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
					<div class="bg-white border border-gray-100 rounded-lg">
						<div class="flex flex-col">
							<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
								<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
									<div class="overflow-hidden sm:rounded-lg">
										<div class="bg-white px-4 py-5 border-b border-gray-100 sm:px-6">
											<h3 class="text-lg leading-6 font-medium">User</h3>
											<p class="mt-1 text-sm leading-5 text-gray-500">
												List of users on Onboardbase
											</p>
										</div>
										<table class="min-w-full divide-y divide-gray-100">
											<thead>
												<tr>
													<th
														class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
													>
														Name
													</th>
													<th
														class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
													>
														Status
													</th>
													<th
														class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
													>
														Org
													</th>
													<th
														class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
													>
														Role
													</th>
													<th
														class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
													>
														Join date
													</th>
													<th
														class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
													>
														Last login
													</th>
													<th
														class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
													>
														Secret Pull
													</th>
												</tr>
											</thead>

											<tbody
												v-if="!usersLoading && !usersError && users?.teamUsers?.length"
												class="bg-white divide-y divide-gray-100"
											>
												<!-- Users -->
												<tr v-for="u in users.teamUsers.slice(0, 5)" :key="u.id">
													<td class="px-6 py-4 whitespace-no-wrap">
														<div class="flex items-center">
															<div v-if="u.user.profilePhoto" class="flex-shrink-0 h-10 w-10">
																<img
																	class="h-10 w-10 rounded-full object-contain object-center"
																	:src="u.user.profilePhoto"
																	:alt="u.user.name"
																/>
															</div>
															<div
																v-else
																class="flex-shrink-0 h-10 w-10 uppercase bg-ob-purple-color text-white overflow-hidden rounded-md font-bold flex justify-center items-center"
															>
																{{ u.user.email[0] }}
															</div>
															<div class="ml-4">
																<div class="text-sm leading-5 font-medium">
																	{{ u.user.name }}
																</div>
																<div class="text-sm leading-5 text-gray-500">
																	{{ u.user.email }}
																</div>
															</div>
														</div>
													</td>
													<td class="px-6 py-4 whitespace-no-wrap">
														<span
															class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
															:class="
																!u.user.isConfirmed || !u.user.active
																	? 'bg-yellow-50 text-yellow-700'
																	: 'bg-green-50 text-green-700'
															"
														>
															{{ !u.user.active || !u.user.isConfirmed ? "Inactive" : "Active" }}
														</span>
													</td>
													<td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
														{{ u.team.name }}
													</td>
													<td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
														<span
															class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-indigo-50 text-indigo-500"
														>
															{{ u.userTeamRole?.name || `N/A` }}
														</span>
													</td>
													<td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium">
														{{ u.user.createdDate.slice(0, 10) }}
													</td>
													<td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium">
														{{ u.user.lastLogin?.slice(0, 10) }}
													</td>
													<td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium">
														{{ u?.userCount || `N/A` }}
													</td>
												</tr>
											</tbody>

											<empty
												v-else-if="users?.teamUsers && users?.teamUsers?.length < 1"
												:message="{ text: `No users` }"
											/>
										</table>

										<error v-if="!usersLoading && usersError" />
										<div v-if="usersLoading && !usersError">
											<tool-skeleton />
											<tool-skeleton />
											<tool-skeleton />
											<tool-skeleton />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>

		<!-- Activity List: Analytics -->
		<div class="">
			<main class="mt-20">
				<div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
					<div class="bg-white border border-gray-100 rounded-lg">
						<div class="flex flex-col">
							<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
								<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
									<div class="overflow-hidden sm:rounded-lg">
										<div
											class="bg-white px-4 py-5 border-b border-gray-100 sm:px-6 flex justify-between"
										>
											<div>
												<h3 class="text-lg leading-6 font-medium text-gray-900">Secret Pulls</h3>
												<p class="mt-1 text-sm leading-5 text-gray-500">
													List of all secret pulls on Onboardbase
												</p>
											</div>
										</div>

										<div v-if="analyticsLoading && !analyticsError">
											<tool-skeleton />
											<tool-skeleton />
											<tool-skeleton />
											<tool-skeleton />
											<tool-skeleton />
										</div>
										<error v-else-if="!analyticsLoading && analyticsError" />

										<empty
											v-else-if="analytics?.totalCount === 0"
											:message="{ text: `There's nothing here, yet` }"
										/>

										<table v-else class="min-w-full divide-y divide-gray-100" id="table">
											<thead>
												<tr>
													<th
														class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
													>
														Users
													</th>
													<th
														class="px-3 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
													>
														Total users Count
													</th>
													<th
														class="px-3 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
													>
														Org Name
													</th>
													<th
														class="px-3 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
													>
														Org Count
													</th>
													<th
														class="px-3 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
													>
														Date
													</th>
												</tr>
											</thead>
											<tbody
												v-if="!analyticsLoading && !analyticsError && !analytics?.length"
												class="bg-white divide-y divide-gray-200"
											>
												<!-- list -->
												<tr
													v-for="analytic in analytics.data"
													:key="analytic.id"
													class="cursor-pointer"
												>
													<td class="px-6 py-4 whitespace-no-wrap">
														<router-link
															:to="'/analytics/' + analytic.id"
															class="flex items-center"
														>
															<div
																class="flex-shrink-0 h-10 w-10 uppercase bg-ob-purple-color text-white overflow-hidden rounded-md font-bold flex justify-center items-center"
															>
																{{ parsedData(analytic.users)[0].name[0] }}
															</div>
															<div class="ml-4">
																<div class="text-sm leading-5 font-medium text-gray-900">
																	<span v-if="parsedData(analytic.users).length === 1">
																		{{ parsedData(analytic.users)[0].name }}
																	</span>

																	<span v-else> {{ parsedData(analytic.users)[0].name }} </span>
																</div>
																<div
																	class="text-xs bg-gray-100 font-medium px-2 py-0.5 mt-0.5 rounded-md text-gray-500"
																>
																	click to see more
																</div>
															</div>
														</router-link>
													</td>
													<td class="px-3 py-4 whitespace-no-wrap">
														<div class="flex items-center">
															<div class="">
																<div class="text-sm leading-5 font-medium text-gray-900">
																	{{ analytic.count }}
																</div>
															</div>
														</div>
													</td>
													<td class="px-3 py-4 whitespace-no-wrap">
														<router-link
															:to="'/analytics/team/' + analytic.id"
															class="flex items-center"
														>
															<div class="">
																<div class="text-sm leading-5 font-medium text-gray-900">
																	<span v-if="parsedData(analytic.team)?.length === 1">
																		{{ parsedData(analytic.team)[0].name }}
																	</span>
																	<span v-else> {{ parsedData(analytic.team)?.length }} team </span>
																</div>
																<div
																	class="text-xs bg-gray-100 font-medium px-2 py-0.5 mt-0.5 rounded-md text-gray-500"
																>
																	click to see more
																</div>
															</div>
														</router-link>
													</td>
													<td class="px-3 py-4 whitespace-no-wrap">
														<div class="flex items-center">
															<div class="">
																<div class="text-sm leading-5 font-medium text-gray-900">
																	{{ analytic.teamCount }}
																</div>
															</div>
														</div>
													</td>

													<td class="px-3 py-4 whitespace-no-wrap">
														<div class="flex items-center">
															<div class="">
																<div class="text-sm leading-5 font-medium text-gray-900">
																	{{ analytic.currentDay }}
																</div>
															</div>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>
		<!-- Subscribers -->

		<div class="">
			<main class="mt-5">
				<div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
					<div class="bg-white border border-gray-100 rounded-lg">
						<div class="flex flex-col">
							<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
								<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
									<div class="overflow-hidden sm:rounded-lg">
										<div class="bg-white px-4 py-5 border-b border-gray-100 sm:px-6">
											<h3 class="text-lg leading-6 font-medium">Subscribers</h3>

											<p class="mt-1 text-sm leading-5 text-gray-500">List of subscribers</p>
										</div>

										<div v-if="blogEmailsLoading">
											<tool-skeleton />

											<tool-skeleton />

											<tool-skeleton />

											<tool-skeleton />
										</div>

										<error v-else-if="blogEmailsError" />

										<empty
											v-else-if="blogEmails?.totalCount < 1"
											:message="{ text: `No subscribers` }"
										/>

										<table v-else class="min-w-full divide-y divide-gray-100">
											<thead>
												<tr>
													<th
														class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
													>
														Email
													</th>

													<th
														class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
													>
														Date added
													</th>
												</tr>
											</thead>

											<tbody class="bg-white divide-y divide-gray-100">
												<!-- Users -->

												<tr v-for="blogEmail in blogEmails.list" :key="blogEmail.id">
													<td class="px-6 py-4 whitespace-no-wrap text-sm leading-5">
														{{ blogEmail.email }}
													</td>

													<td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium">
														{{ blogEmail.createdDate.slice(0, 10) }}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>

		<!-- Activity: demos -->

		<!-- <div class="">

			<main class="mt-5">

				<div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">

					<div class="bg-white border border-gray-100 rounded-lg">

						<div class="flex flex-col">

							<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">

								<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">

									<div class="overflow-hidden sm:rounded-lg">

										<div class="bg-white px-4 py-5 border-b border-gray-100 sm:px-6">

											<h3 class="text-lg leading-6 font-medium">Demo's booked</h3>

											<p class="mt-1 text-sm leading-5 text-gray-500">

												List demo's booked on Onboardbase

											</p>

										</div>



										<div v-if="getDemosLoading">

											<tool-skeleton />

											<tool-skeleton />

											<tool-skeleton />

											<tool-skeleton />

										</div>

										<error v-else-if="getDemosError" />

										<empty

											v-else-if="getDemos?.totalCount < 1"

											:message="{ text: `No demo's booked yet` }"

										/>



										<table v-else class="min-w-full divide-y divide-gray-100">

											<thead>

												<tr>

													<th

														class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"

													>

														Name

													</th>

													<th

														class="px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"

													>

														Date added

													</th>

												</tr>

											</thead>



											<tbody class="bg-white divide-y divide-gray-100">

												<tr v-for="getDemo in getDemos?.list" :key="getDemo.id">

													<td class="px-6 py-4 whitespace-no-wrap">

														<div class="flex items-center">

															<div

																class="flex-shrink-0 h-10 w-10 uppercase bg-ob-purple-color text-white overflow-hidden rounded-md flex justify-center items-center"

															>

																{{ getDemo.name[0] }}

															</div>



															<div class="ml-4">

																<div class="text-sm leading-5 font-medium">

																	{{ getDemo.name }}

																</div>

																<div class="text-sm leading-5 text-gray-500">

																	{{ getDemo.email }}

																</div>

															</div>

														</div>

													</td>



													<td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium">

														{{ getDemo.createdDate.slice(0, 10) }}

													</td>

												</tr>

											</tbody>

										</table>

									</div>

								</div>

							</div>

						</div>

					</div>

				</div>

			</main>

		</div> -->
	</div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";

import { autoPick } from "@/utils/index";
import { dif_btw_dates } from "@/utils/date.js";
import { formatDate } from "@/utils/date";

import { useQuery, useResult } from "@vue/apollo-composable";

import {
	waitlistQuery,
	teamsQuery,
	usersQuery,
	getBlogsQuery,
	blogEmailsQuery,
	getDemosQuery,
	getAnalyticsDataQuery,
} from "../../graphql/query";

import ToolSkeleton from "../../components/skeleton/ToolSkeleton.vue";

import Error from "../../components/Error.vue";

import Empty from "../../components/Empty.vue";
import { roundToTwo } from "../../utils";
import { useRouter } from "vue-router";
import { parsedData } from "../../utils";

export default {
	name: "Home",

	components: {
		ToolSkeleton,
		Error,
		Empty,
	},

	setup() {
		const store = useStore();
		const user = computed(() => store.state.user);
		const showNew = (d) => dif_btw_dates(d);
		const tools = ref([]);
		const usersCount = ref(0);
		const nextPage = ref(0);
		const pattern = ref("");
		const router = useRouter();

		const getSecretCount = (noOfTeamProjectsCount) => {
			let initialState = {
				AK: "0",

				// N: "0",

				// CC: "0",

				PW: "0",

				// FL: "0"
			};

			if (noOfTeamProjectsCount.length) {
				for (const i of noOfTeamProjectsCount) {
					switch (i.type) {
						case "api_keys":
							initialState["AK"] = i.count;

							break;

						case "auth":
							initialState["PW"] = i.count;

							break;

						// case 'credit_card':

						// 	initialState["CC"] = i.count;

						// 	break;

						// case 'document':

						// 	initialState["N"] = i.count;

						// 	break;

						// case 'file':

						// 	initialState["FL"] = i.count;

						// 	break;

						default:
							break;
					}
				}
			}

			return initialState;
		};

		const getOwner = (arr) => {
			const user = arr.filter(
				(each) => each.userTeamRole && each.userTeamRole.name.toLowerCase() === "owner"
			);

			if (user[0]) {
				return {
					owner: user[0].user.email,

					lastLogin: user[0].user.lastLogin,
				};
			}

			return null;
		};

		const {
			result: waitlistResult,

			loading: waitlistLoading,

			error: waitlistError,
		} = useQuery(waitlistQuery, {
			take: 5,

			skip: 0,
		});

		const waitlist = useResult(waitlistResult);

		const {
			result: blogEmailsResult,

			loading: blogEmailsLoading,

			error: blogEmailsError,
		} = useQuery(blogEmailsQuery, {
			take: 5,

			skip: 0,
		});

		const blogEmails = useResult(blogEmailsResult);

		const {
			result: teamsResult,

			loading: teamsLoading,

			error: teamsError,
		} = useQuery(teamsQuery, {
			take: 5,

			skip: 0,
		});

		const teams = useResult(teamsResult);

		const {
			loading: usersLoading,

			error: usersError,

			result: getUsersRes,
		} = useQuery(usersQuery, {
			take: 5,

			skip: 0,
		});

		const users = useResult(getUsersRes);

		const userss = [];

		const {
			result: getBlogsResult,

			loading: getBlogsLoading,

			error: getBlogsError,
		} = useQuery(getBlogsQuery, { take: 5, skip: 0 });

		const getBlogs = useResult(getBlogsResult);

		const {
			result: getDemosResult,

			loading: getDemosLoading,

			error: getDemosError,
		} = useQuery(getDemosQuery, {
			take: 5,

			skip: 0,
		});

		const getDemos = useResult(getDemosResult);

		const showReturnedUsersModal = () => {
			store.commit("setReturnedUserModal", {
				state: true,
			});
		};

		const showSecretPullModal = () => {
			store.commit("setSecretPullModal", {
				state: true,
			});
		};

		const {
			result: analyticsResult,
			loading: analyticsLoading,
			error: analyticsError,
		} = useQuery(getAnalyticsDataQuery, {
			take: 5,
			skip: 0,
		});

		const analytics = computed(() => autoPick(analyticsResult.value));

		const routePage = (id) => {
			router.push(`/analytics/${id}`);
		};

		return {
			user,
			parsedData,
			routePage,
			formatDate,
			roundToTwo,
			getDemosError,
			getDemosLoading,
			getDemos,
			blogEmails,
			blogEmailsError,
			blogEmailsLoading,
			getBlogsError,
			getBlogsLoading,
			getBlogs,
			userss,
			showNew,
			tools,
			waitlistLoading,
			waitlistError,
			waitlist,
			teams,
			users,
			usersCount,
			usersError,
			usersLoading,
			teamsLoading,
			teamsError,
			nextPage,
			pattern,
			getOwner,
			getSecretCount,
			analytics,
			analyticsError,
			analyticsLoading,
			showSecretPullModal,
			showReturnedUsersModal,
		};
	},
};
</script>
