import { createStore } from "vuex";

let timer;
export default createStore({
	state: {
		online: null,
		serverError: { state: false, internetError: false },
		resendConfirmationEmail: null,
		globaAlert: { show: false, text: "", type: "" },
		loading: false,
		admins: [],
		deleteEmployeeModal: { state: false, email: null, id: null },
		// currentTeamTools: null,
		showToolDelModal: false,
		user: {},
		tools: [],
		teams: [],
		waitlist: [],
		users: [],
		fromLogin: false,
		deleteWaitlistModal: { state: false },
		deleteTeamModal: { state: false },
		loginEmail: null,
		returnedUserModal: { state: false },
		secretPullModal: { state: false },
		couponModal: { state: false },
		planModal: { state: false },
		updateTierModal: { state: false },
		editEnterprisePlanModal: { state: false },
	},

	mutations: {
		setEditEnterprisePlanModal(state, payload) {
			state.editEnterprisePlanModal = payload;
		},
		setUpdateTierModal(state, payload) {
			state.updateTierModal = payload;
		},
		setPlanModal(state, payload) {
			state.planModal = payload;
		},
		setCouponModal(state, payload) {
			state.couponModal = payload;
		},
		setReturnedUserModal(state, payload) {
			state.returnedUserModal = payload;
		},
		setSecretPullModal(state, payload) {
			state.secretPullModal = payload;
		},
		setFromLogin(state, payload) {
			state.fromLogin = payload;
		},
		setLoginEmail(state, payload) {
			state.loginEmail = payload;
		},
		setServerError(state, payload) {
			state.serverError = payload;
		},
		setOnline(state, payload) {
			if (state.online === true && payload === true) return (state.online = null);
			if (state.online === false && payload === true) return (state.online = true);
			if (state.online === null && payload === true) return (state.online = null);
			if (state.online === null && payload === false) return (state.online = false);
			if (state.online === false && payload === false) return (state.online = false);
		},

		setResendConfirmationEmail(state, payload) {
			state.resendConfirmationEmail = payload;
		},
		obalAlert(state, payload) {
			// Clear previous timer
			clearTimeout(timer);
			// update alert state
			state.globaAlert = payload;
			// set new timer
			timer = setTimeout(() => (state.globaAlert = { show: false, text: "", type: "" }), 5000);
		},

		setLoading(state, payload) {
			state.loading = payload;
		},

		toggleDropdown1(state) {
			state.dropdown1 = !state.dropdown1;
		},

		setGlobalAlert(state, payload) {
			// Clear previous timer
			clearTimeout(timer);
			// update alert state
			state.globaAlert = payload;
			// set new timer
			timer = setTimeout(() => (state.globaAlert = { show: false, text: "", type: "" }), 5000);
		},

		setUser(state, payload) {
			state.user = payload;
		},

		setAuth(state, payload) {
			state.authStatus = payload;
		},

		setToken(state, payload) {
			state.token = payload;
		},

		setDropdown1(state, payload) {
			state.dropdown1 = payload;
		},
		setDeleteWaitlistModal(state, payload) {
			state.deleteWaitlistModal = payload;
		},
		setDeleteTeamModal(state, payload) {
			state.deleteTeamModal = payload;
		},
	},

	actions: {},

	modules: {},

	getters: {},
});
