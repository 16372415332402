<template>
	<div class="fixed z-30 inset-0 overflow-y-auto">
		<div
			class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
		>
			<div class="fixed inset-0 transition-opacity" aria-hidden="true">
				<div
					@click.self="$emit('closeModal')"
					class="absolute inset-0 bg-gray-800 bg-opacity-75 modal-blur"
				></div>
			</div>
			<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"
				>&#8203;</span
			>
			<slot name="modal-content"> NO CONTENT</slot>
		</div>
	</div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
	name: "Modal Wrapper",
	emits: ["closeModal"],
	setup() {
		const store = useStore();
		const show = computed(() => store.state.modal);

		return {
			show,
		};
	},
};
</script>

<style lang="scss" scoped>
.fadeIn-enter-active,
.fadeIn-leave-active {
	.main {
		transition: all 0.3s ease-in-out;
	}
}
.fadeIn-leave-to,
.fadeIn-enter-from {
	.main {
		transform: scale(0.8);
		opacity: 0;
	}
}
</style>
