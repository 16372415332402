<template>
	<div class="my-2 mx-3 bg-gray-200 animate-pulse rounded px-5 py-3 mb-2"></div>
</template>

<script>
export default {
	name: "ListSkeleton",
};
</script>

<style lang="scss" scoped></style>
