<template>
	<nav @click.self="clearDropdowns" class="bg-dark-color lg:px-12">
		<div @click.self="clearDropdowns" class="max-w-7xl mx-auto sm:px-6">
			<div class="">
				<div
					@click.self="
						() => {
							clearDropdowns();
							specDD();
						}
					"
					class="flex items-center justify-between h-16 px-4 sm:px-0"
				>
					<div @click="clearDropdowns" class="flex items-center">
						<div @click="clearDropdowns" class="flex-shrink-0">
							<router-link :to="`/`" exact>
								<img class="h-6 w-auto" src="@/assets/img/new-logo.png" alt="Onboard logo" />
							</router-link>
						</div>
						<div class="hidden md:block ml-8">
							<div
								class="ml-10 flex items-baseline space-x-4 relative tour_navigation"
								@click="clearDropdowns"
							>
								<navlink v-for="link in navLinks" :key="link.name" :link="link" />
							</div>
						</div>
					</div>
					<div class="hidden md:block">
						<div class="ml-4 flex items-center md:ml-6">
							<div class="relative">
								<div
									class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg"
									style="display: none"
								>
									<div class="py-1 rounded-md bg-white shadow-xs">
										<a
											href="#"
											class="block px-4 py-2 text-sm text-cool-gray-700 hover:bg-cool-gray-100 transition ease-in-out duration-150"
											role="menuitem"
											>Your Profile</a
										>
										<a
											href="#"
											class="block px-4 py-2 text-sm text-cool-gray-700 hover:bg-cool-gray-100 transition ease-in-out duration-150"
											role="menuitem"
											>Settings</a
										>
										<a
											href="#"
											class="block px-4 py-2 text-sm text-cool-gray-700 hover:bg-cool-gray-100 transition ease-in-out duration-150"
											role="menuitem"
											>Logout</a
										>
									</div>
								</div>
							</div>
							<!-- Profile dropdown -->
							<div class="relative">
								<div class="flex items-center">
									<button
										class="max-w-xs flex ml-6 items-center text-sm rounded-full text-white focus:outline-none"
										id="user-menu"
										aria-label="User menu"
										aria-haspopup="true"
										@click="toggleModal"
									>
										<img
											v-if="user.isConfirmed && user.profilePhoto"
											class="h-8 w-8 rounded-full object-cover border-2 border-gray-700 hover:border-gray-500"
											:src="user.profilePhoto"
											alt=""
										/>
										<span
											v-else
											class="avatar inline-flex items-center justify-center h-8 w-8 rounded-full bg-ob-purple-color border-2 border-gray-700 hover:border-gray-100 transition ease-in-out duration-150"
										>
											<span class="text-xs font-medium leading-none text-white uppercase">{{
												user.name && userInitials(user.name)
											}}</span>
										</span>
									</button>
								</div>

								<!-- Nav dropdown  -->
								<div v-if="dropdown1" class="origin-top-right absolute right-0 mt-2 w-64 z-50">
									<div class="rounded-md bg-white shadow-lg">
										<div class="py-1">
											<div class="border-b border-gray-100 py-2 px-4">
												<div class="flex items-center">
													<div class="flex-shrink-0 mr-3">
														<img
															v-if="user.isConfirmed && user.profilePhoto"
															class="h-8 w-8 rounded-full object-cover border-2 border-gray-300 hover:border-gray-100"
															:src="user.profilePhoto"
															alt=""
														/>
														<span
															v-else
															class="avatar inline-flex items-center justify-center h-8 w-8 rounded-full bg-ob-purple-color border-2 border-gray-300 hover:border-gray-100 transition ease-in-out duration-150"
														>
															<span class="text-xs font-medium leading-none text-white uppercase">{{
																user.name && userInitials(user.name)
															}}</span>
														</span>
													</div>
													<div class="">
														<div class="text-sm font-semibold leading-5">
															<span>{{ user.name && user.name }}</span>
														</div>
														<div class="text-xs font-small leading-none text-gray-400">
															{{ user.email }}
														</div>
													</div>
												</div>
											</div>

											<button
												@click="logout"
												class="w-full px-4 py-2 text-left text-xs font-medium text-gray-700 hover:bg-gray-100 focus:outline-none flex items-center"
											>
												<svg
													width="20"
													height="20"
													fill="none"
													class="flex-none mr-3 text-gray-400 group-hover:text-teal-600"
												>
													<path
														d="M10.25 3.75H9A6.25 6.25 0 002.75 10v0A6.25 6.25 0 009 16.25h1.25M10.75 10h6.5M14.75 12.25l2.5-2.25-2.5-2.25"
														stroke="currentColor"
														stroke-width="1.5"
														stroke-linecap="round"
														stroke-linejoin="round"
													></path>
												</svg>
												Sign Out
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="-mr-2 flex md:hidden">
						<!-- Mobile menu button -->
						<button
							@click="toggleMenu"
							class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
						>
							<svg
								v-if="openMenu === false"
								class="block h-6 w-6"
								stroke="currentColor"
								fill="none"
								viewBox="0 0 24 24"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M4 6h16M4 12h16M4 18h16"
								/>
							</svg>
							<svg
								v-if="openMenu === true"
								class="h-6 w-6"
								stroke="currentColor"
								fill="none"
								viewBox="0 0 24 24"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="md:hidden" v-if="openMenu">
			<div class="px-2 py-3 space-y-1 sm:px-3">
				<router-link
					v-for="link in navLinks"
					:key="link.name"
					:to="link.to"
					exact
					active-class="router-link-active"
					class="px-3 py-2 rounded-md text-sm font-medium text-ob-gray-color hover:bg-gray-800 focus:outline-none focus:bg-gray-700"
				>
					<span>{{ link.name }}</span>
				</router-link>
			</div>

			<div class="pt-4 pb-3 border-t border-gray-700">
				<div class="flex items-center px-5 space-x-3">
					<div class="flex-shrink-0">
						<span
							class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-ob-purple-color border-2 border-gray-900 hover:border-gray-300"
						>
							<span class="text-xs font-medium leading-none text-white uppercase">{{
								user.name && userInitials(user.name)
							}}</span>
						</span>
					</div>
					<div class="space-y-1">
						<div class="text-base font-small leading-none text-white">
							{{ user.name && user.name }}
						</div>
					</div>
				</div>
				<div
					class="mt-3 px-2 space-y-1"
					role="menu"
					aria-orientation="vertical"
					aria-labelledby="user-menu"
				>
					<button
						@click="logout"
						class="px-3 py-2 rounded-md text-base font-small text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 flex items-center"
					>
						<svg
							width="14"
							height="14"
							fill="none"
							class="flex-none mr-3 text-gray-400 group-hover:text-teal-600"
						>
							<path
								d="M10.25 3.75H9A6.25 6.25 0 002.75 10v0A6.25 6.25 0 009 16.25h1.25M10.75 10h6.5M14.75 12.25l2.5-2.25-2.5-2.25"
								stroke="currentColor"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							></path>
						</svg>
						Sign out
					</button>
				</div>
			</div>
		</div>
	</nav>
</template>
<script>
import { computed, reactive, ref, toRefs, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import NavLink from "@/components/NavLink";
// import FeedbackDropdown from "@/components/dropdowns/FeedbackDropdown";

import { userInitials } from "@/utils/index";
// import TeamNavDropdown from "./dropdowns/TeamNavDropdown.vue";

export default {
	name: "NavComponent",

	components: {
		navlink: NavLink,
		// FeedbackDropdown,
		// TeamNavDropdown
	},

	setup() {
		const router = useRoute();
		const store = useStore();
		const routeName = ref(router.name);
		const routeNames = ["profile", "changepassword"];
		// const role = computed(() => store.state.role);
		const state = reactive({
			user: computed(() => store.state.user),
			// currentTeam: computed(() => store.state.currentTeam),
			dropdown1: computed(() => store.state.dropdown1),
			// dropdown2: computed(() => store.state.dropdown2),
			// toolTip: null,
			// employees: computed(() => store.state.currentTeamUsers),
			// showNotification: computed(() => store.state.showNotification),
			// showTeamNav: computed(() => store.state.showTeamNav),
			initials: computed(() => {
				let names = state.user.name.split(" ");
				if (names.length > 1) return names[0][0] + names[1][0];
				if (!names[0]) return "";
				return names[0][0];
			}),
		});

		const toggleNotification = () => {
			store.commit("setDropdown1", false);
			// store.commit("setShowNotification", !state.showNotification);
		};

		const toggleTeamNavBar = () => {
			store.commit("setDropdown1", false);
			// store.commit("setShowNotification", false);
		};

		const updateToolTip = (val) => (state.toolTip = val);

		watch(
			() => router.name,
			() => (routeName.value = router.name)
		);

		const clearDropdowns = () => {
			store.commit("setDropdown1", false);
			// store.commit("setShowNotification", false);
		};

		return {
			routeName,
			routeNames,
			// role,
			toggleTeamNavBar,
			...toRefs(state),
			updateToolTip,
			toggleNotification,
			clearDropdowns,
		};
	},

	data() {
		const navLinks = [
			{ name: "Orgs.", to: "/teams", auth: "all" },
			{ name: "Users", to: "/users", auth: "all" },
			// { name: "Blog", to: "/blog", auth: "all" },
			{ name: "Subscribers", to: "/subscribers", auth: "all" },
			// { name: "Demos", to: "/demo", auth: "all" },
			// { name: "Tools", to: "/tools", auth: "all" },
			// { name: "Waitlist", to: "/waitlist", auth: "all" },
			// { name: "Config", to: "/setup", auth: "employee" }
			{ name: "Analytics", to: "/analytics", auth: "all" },
			{ name: "Billings", to: "/billings", auth: "all" },
		];

		const openMenu = false;
		const showdDropdown3 = false;

		return { navLinks, openMenu, showdDropdown3, userInitials };
	},

	methods: {
		// Mobile Menu
		toggleMenu() {
			this.openMenu = !this.openMenu;
		},

		// first dropdown
		toggleModal() {
			this.$store.commit("toggleDropdown1");
		},

		// Second dropdown

		// specDD() {
		// 	this.$store.commit("setOrgDropdown", false);
		// 	this.$store.commit("setSetupDropdown", false);
		// 	this.$store.commit("setShowNotification", false);
		// },

		logout() {
			this.clearDropdowns();
			localStorage.removeItem("o-token-ap");
			this.$router.push("/login");
		},
	},
};
</script>

<style scoped>
.router-link-exact-active {
	@apply bg-black;
}
</style>
